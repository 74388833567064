import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import Fab from '@material-ui/core/Fab';
import { useHistory } from 'react-router-dom';
import AlertProvider from '../contexts/AlertProvider';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 0px 8px 16px !important',
        overflow: 'hidden !important',
        minHeight: 64
    },
    fabProgress: {
        color: '#4a94ff',
        position: 'absolute',
        top: 13,
        left: 16,
        zIndex: 1
    },
    actions: {
        marginLeft: 'auto',
        marginRight: '16px'
    },
    status: {
        border: '2px solid #5bb2ff',
        color: '#4a94ff',
        background: '#f8fbff',
        boxShadow: 'unset',
        '&:hover': {
            background: 'unset'
        }
    },
    margin: {
        margin: 16
    }
}));

const AuthenticatedAlert = (props) => {
    const classes = useStyles();
    const timer = useRef();
    const history = useHistory();
    const alert = useContext(AlertProvider.context);

    const { setNavBarSync } = props;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        timer.current = setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const handleClose = () => {
        alert.setAlert({ status: 3 });
    };

    const handleClick = () => {
        alert.setAlert({ status: '' });
        setNavBarSync(true);
        history.push('/map');
    };

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                fullWidth={true}
                maxWidth='sm'
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogContent className={classes.content}>
                    <Fab aria-label='status' className={classes.status}>
                        {loading ? (
                            <HourglassEmptyOutlinedIcon
                                style={{ color: 'rgba(56, 57, 60, 0.79)' }}
                            />
                        ) : (
                            <CheckIcon />
                        )}
                    </Fab>

                    <Typography
                        className={classes.margin}
                        style={{ fontSize: '1.1rem', color: 'rgba(56, 57, 60, 0.97)' }}
                    >
                        {loading ? 'Authenticating...' : 'You are now logged in! Continue to Map'}
                    </Typography>

                    {!loading && (
                        <div className={classes.actions}>
                            <Tooltip title='Go To Map Explorer'>
                                <IconButton aria-label='Go To Map Explorer' onClick={handleClick}>
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AuthenticatedAlert;
