import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import DataGrid from 'react-data-grid';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useWindowDimensions from '../Dimensions/WindowDimensions';
import TableHeader from './TableHeader';
import Filled from '../Filled/Filled';
import TableModal from './TableModal/TableModal';
import TableLoading from './Loading';
import { activateFeature } from '../lib/maplib';
import { Filter } from './Filters';
import { sortBy } from 'underscore';
import { formatPrice, roundString } from '../lib/formatlib';
import styles from './style.css';

const useStyles = makeStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        zIndex: 3
    },
    headerCell: {
        color: '#3c3b3f',
        fontSize: ' 0.875rem',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        '&:after': {
            content: "''",
            position: 'absolute',
            borderLeft: '2px solid rgb(220, 227, 233)',
            top: '35%',
            left: '0',
            height: '40%',
            marginTop: 'auto',
            marginBottom: 'auto'
        }
    },
    grid: {
        height: '100%',
        overflowY: 'hidden',
        position: 'relative'
    }
});

const createData = (data, setRange) => {
    const filterData = [];
    const range = [0, 0];

    data.features.forEach((value) => {
        value.properties['coordinates'] = value.geometry.coordinates;
        value.properties['ntt_sorted_id'] = parseInt(value.properties['ntt_sorted_id']);
        value.properties['net_present_cost'] = roundString(value.properties['net_present_cost'], 0);
        value.properties['acres'] = roundString(value.properties['acres'], 2);
        value.properties['delta_total_p_lbs_yr'] = roundString(
            value.properties['delta_total_p_lbs_yr'],
            2
        );
        value.properties['delta_total_n_lbs_yr'] = roundString(
            value.properties['delta_total_n_lbs_yr'],
            2
        );
        value.properties['delta_sed_tons_yr'] = roundString(
            value.properties['delta_sed_tons_yr'],
            2
        );
        value.properties['efficiency'] = roundString(value.properties['efficiency'], 4);

        // configure range
        if (value.properties['efficiency'] > range[1]) {
            range[1] = value.properties['efficiency'];
        }

        filterData.push(value.properties);
    });

    setRange(range);
    return filterData;
};

const NttTable = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { height, width } = useWindowDimensions();
    const { user, map, data, setNavBarSync } = props;

    const [rows, setRows] = useState([]);
    const [rowsCopy, setRowsCopy] = useState([]);
    const [selected, setSelected] = useState(new Set());

    const [range, setRange] = useState([0, 0]);
    const [sortColumn, setSortColumn] = useState('efficiency');
    const [sortDirection, setSortDirection] = useState('DESC');

    const [tpUplift, setTpUplift] = useState(0);
    const [tnUplift, setTnUplift] = useState(0);
    const [sedUplift, setSedUplift] = useState(0);
    const [cost, setCost] = useState(0);

    const [options, setOptions] = useState({});

    const [open, setOpen] = useState(false);
    let padding = width > 1670 ? (width - 1770 - 20) / 10 : 0;

    useEffect(() => {
        if (data !== null) {
            const cleanData = createData(data, setRange);
            setRows(cleanData);
            setRowsCopy(cleanData);
        }
    }, [data]);

    const ActionCell = () => {
        const handleClick = () => {
            setSelected(new Set());
            setTpUplift(0);
            setTnUplift(0);
            setSedUplift(0);
            setCost(0);
        };

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    color: '#3c3b3f',
                    fontSize: '0.875rem',
                    fontWeight: 500
                }}
            >
                <Checkbox
                    style={{ padding: '0px', margin: 'auto' }}
                    color='primary'
                    indeterminate={selected.size !== 0}
                    checked={selected.size !== 0}
                    onClick={handleClick}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Tooltip title='Locate'>
                    <IconButton style={{ padding: 0, margin: 'auto' }} aria-label='sort'>
                        <PinDropIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const FilterCell = ({ column }) => {
        return (
            <div className={classes.headerCell}>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={column.sortable ? () => sort(column.key) : () => {}}
                >
                    {column.name}
                </span>

                {sortColumn === column.key && sortDirection === 'ASC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowUpwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                {sortColumn === column.key && sortDirection === 'DESC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowDownwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                <Filter
                    rows={rowsCopy}
                    setRows={setRows}
                    options={options}
                    setOptions={setOptions}
                    column={column.key}
                />
            </div>
        );
    };

    const HeaderCell = ({ column }) => {
        return (
            <div className={classes.headerCell}>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={column.sortable ? () => sort(column.key) : () => {}}
                >
                    {column.name}
                </span>

                {sortColumn === column.key && sortDirection === 'ASC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowUpwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                {sortColumn === column.key && sortDirection === 'DESC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowDownwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}
            </div>
        );
    };

    const columns = [
        {
            width: 120,
            name: 'Select',
            key: 'Select',
            formatter: (data) => SelectFormatter(data),
            headerRenderer: ActionCell
        },
        {
            width: 100 + padding,
            name: 'Rank',
            key: 'ntt_sorted_id',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 120 + padding,
            name: 'Field ID',
            key: 'field_id',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 160 + padding,
            name: 'TP Uplift (lbs/yr)',
            key: 'delta_total_p_lbs_yr',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 160 + padding,
            name: 'TN Uplift (lbs/yr)',
            key: 'delta_total_n_lbs_yr',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 180 + padding,
            name: 'Sed. Uplift (tons/yr)',
            key: 'delta_sed_tons_yr',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 150 + padding,
            name: 'Project cost ($)',
            key: 'net_present_cost',
            formatter: (data) => PriceFormatter(data.row.net_present_cost),
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 170 + padding,
            name: 'Efficiency (lbs/yr/$)',
            key: 'efficiency',
            formatter: (data) => FillFormatter(data),
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 150 + padding,
            name: '2018 Crop',
            key: 'croptype',
            sortable: true,
            headerRenderer: FilterCell
        },
        {
            width: 150 + padding,
            name: 'Irrigation Type',
            key: 'irrtype',
            sortable: true,
            headerRenderer: FilterCell
        },

        {
            width: 120 + padding,
            name: 'Acres',
            key: 'acres',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 190 + padding,
            name: 'Recommended BMP',
            key: 'scenario_text',
            sortable: true,
            headerRenderer: FilterCell
        }
    ];

    const PriceFormatter = (value) => {
        return <span>${formatPrice(value)} </span>;
    };

    const FillFormatter = (data) => {
        return (
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    lineHeight: '27px',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <Filled value={data.row.efficiency} min={range[0]} max={range[1]} projectRank={data.row.ntt_sorted_id} totalProjects={rows.length}/>
            </div>
        );
    };

    const SelectFormatter = (data) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Checkbox
                    style={{ padding: '0px', margin: 'auto' }}
                    color='primary'
                    checked={selected.has(data.row.ntt_sorted_id)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />

                <Tooltip title='Locate'>
                    <IconButton
                        onClick={() => handleLocateClick(data)}
                        style={{ padding: 0, margin: 'auto' }}
                        aria-label='sort'
                    >
                        <PinDropIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const handleRowClick = (index, data) => {
        setSelected((state) => {
            if (state.has(data.ntt_sorted_id)) {
                setTpUplift(tpUplift - data.delta_total_p_lbs_yr);
                setTnUplift(tnUplift - data.delta_total_n_lbs_yr);
                setSedUplift(sedUplift - data.delta_sed_tons_yr);
                setCost(cost - data.net_present_cost);
                return new Set([...state].filter((x) => x !== data.ntt_sorted_id));
            } else {
                setTpUplift(tpUplift + data.delta_total_p_lbs_yr);
                setTnUplift(tnUplift + data.delta_total_n_lbs_yr);
                setSedUplift(sedUplift + data.delta_sed_tons_yr);
                setCost(cost + data.net_present_cost);
                return new Set(state).add(data.ntt_sorted_id);
            }
        });
    };

    const handleLocateClick = (data) => {
        activateFeature(map, 'ntt-layer', data.row.coordinates, data.row);
        history.push('/map');
        setNavBarSync(true);
    };

    const sort = (value) => {
        setRows(sortDirection === 'ASC' ? sortBy(rows, value).reverse() : sortBy(rows, value));
        setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        setSortColumn(value);
    };

    return (
        <div className={classes.root}>
            <TableHeader
                map={map}
                title={'Field Sediment Runoff Reduction Projects'}
                info={[
                    { name: 'TP Uplift (lbs/yr)', value: roundString(tpUplift, 0) },
                    { name: 'TN Uplift (lbs/yr)', value: roundString(tnUplift, 0) },
                    { name: 'Sed Uplift (tons/yr)', value: roundString(sedUplift, 0) },
                    { name: 'Cost', value: formatPrice(cost) }
                ]}
                data={data}
                rows={rows}
                setRows={setRows}
                selected={selected}
                handleAdd={() => setOpen(true)}
            />

            <div className={classes.grid}>
                <TableLoading loading={data === null} empty={rows.length === 0} />
                <DataGrid
                    rowKey='id'
                    columns={columns}
                    rows={rows}
                    onRowClick={handleRowClick}
                    headerRowHeight={50}
                    rowHeight={38}
                />
            </div>

            <TableModal
                type='ntt'
                user={user}
                selected={selected}
                setSelected={setSelected}
                open={open}
                setOpen={setOpen}
                onAdd={() => {
                    setTpUplift(0);
                    setTnUplift(0);
                    setSedUplift(0);
                    setCost(0);
                }}
            />
        </div>
    );
};

export default NttTable;
