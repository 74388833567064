import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import DataGrid from 'react-data-grid';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useWindowDimensions from '../Dimensions/WindowDimensions';
import TableHeader from './TableHeader';
import Filled from '../Filled/Filled';
import TableModal from './TableModal/TableModal';
import TableLoading from './Loading';
import { Filter } from './Filters';
import { activateFeature } from '../lib/maplib';
import { sortBy } from 'underscore';
import { formatNumber, formatPrice, roundString } from '../lib/formatlib';
import styles from './style.css';

const useStyles = makeStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        zIndex: 3
    },
    headerCell: {
        color: '#3c3b3f',
        fontSize: ' 0.875rem',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        '&:after': {
            content: "''",
            position: 'absolute',
            borderLeft: '2px solid rgb(220, 227, 233)',
            top: '35%',
            left: '0',
            height: '40%',
            marginTop: 'auto',
            marginBottom: 'auto'
        }
    },
    grid: {
        height: '100%',
        overflowY: 'hidden',
        position: 'relative'
    }
});

const createData = (data, setRange) => {
    const filterData = [];
    const range = [0, 0];

    data.features.forEach((value) => {
        value.properties['coordinates'] = value.geometry.coordinates;
        value.properties['sorted_id'] = parseInt(value.properties['sorted_id']);
        value.properties['cost'] = roundString(value.properties['cost'], 0);
        value.properties['stream_km'] = roundString(value.properties['stream_km'], 2);
        value.properties['uplift'] = roundString(value.properties['uplift'], 1);
        value.properties['planting_a'] = roundString(value.properties['planting_a'], 2);
        value.properties['efficiency'] = roundString(value.properties['efficiency'], 2);

        // configure range
        if (value.properties['efficiency'] > range[1]) {
            range[1] = value.properties['efficiency'];
        }

        filterData.push(value.properties);
    });

    setRange(range);
    return filterData;
};

const ShadeTable = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { height, width } = useWindowDimensions();
    const { user, map, data, setNavBarSync } = props;

    const [rows, setRows] = useState([]);
    const [rowsCopy, setRowsCopy] = useState([]);
    const [selected, setSelected] = useState(new Set());

    const [range, setRange] = useState([0, 0]);
    const [sortColumn, setSortColumn] = useState('efficiency');
    const [sortDirection, setSortDirection] = useState('DESC');

    const [solarLoadImpact, setSolarLoadImpact] = useState(0);
    const [cost, setCost] = useState(0);
    const [options, setOptions] = useState({});

    const [open, setOpen] = useState(false);
    let padding = width > 1460 ? (width - 1460 - 20) / 7 : 0;

    useEffect(() => {
        if (data !== null) {
            const cleanData = createData(data, setRange);
            setRows(cleanData);
            setRowsCopy(cleanData);
        }
    }, [data]);

    const ActionCell = () => {
        const handleClick = () => {
            setSelected(new Set());
            setSolarLoadImpact(0);
            setCost(0);
        };

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    color: '#3c3b3f',
                    fontSize: ' 0.875rem',
                    fontWeight: 500
                }}
            >
                <Checkbox
                    style={{ padding: '0px', margin: 'auto' }}
                    color='primary'
                    indeterminate={selected.size !== 0}
                    checked={selected.size !== 0}
                    onClick={handleClick}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Tooltip title='Locate'>
                    <IconButton style={{ padding: 0, margin: 'auto' }} aria-label='sort'>
                        <PinDropIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const FilterCell = ({ column }) => {
        return (
            <div className={classes.headerCell}>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={column.sortable ? () => sort(column.key) : () => {}}
                >
                    {column.name}
                </span>

                {sortColumn === column.key && sortDirection === 'ASC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowUpwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                {sortColumn === column.key && sortDirection === 'DESC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowDownwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                <Filter
                    rows={rowsCopy}
                    setRows={setRows}
                    options={options}
                    setOptions={setOptions}
                    column={column.key}
                />
            </div>
        );
    };

    const HeaderCell = ({ column }) => {
        return (
            <div className={classes.headerCell}>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={column.sortable ? () => sort(column.key) : () => {}}
                >
                    {column.name}
                </span>

                {sortColumn === column.key && sortDirection === 'ASC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowUpwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                {sortColumn === column.key && sortDirection === 'DESC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowDownwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}
            </div>
        );
    };

    const columns = [
        {
            width: 120,
            name: 'Select',
            key: 'Select',
            formatter: (data) => SelectFormatter(data),
            headerRenderer: ActionCell
        },
        {
            width: 100 + padding,
            name: 'Rank',
            key: 'sorted_id',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 220 + padding,
            name: 'Blocked solar load (kcals)',
            key: 'uplift',
            formatter: (data) => NumberFormatter(data.row.uplift),
            sortable: true,
            headerRenderer: HeaderCell
        },

        {
            width: 160 + padding,
            name: 'Cost ($)',
            key: 'cost',
            formatter: (data) => PriceFormatter(data.row.cost),
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 180 + padding,
            name: 'Efficiency (kcals/$)',
            key: 'efficiency',
            formatter: (data) => FillFormatter(data),
            sortable: true,
            headerRenderer: HeaderCell
        },

        {
            width: 170 + padding,
            name: 'Stream',
            key: 'stream_name',
            sortable: true,
            headerRenderer: FilterCell
        },

        {
            width: 170 + padding,
            name: 'Reach (stream km)',
            key: 'stream_km',
            sortable: true,
            headerRenderer: HeaderCell
        },

        {
            width: 180 + padding,
            name: 'Project Type',
            key: 'proj_type',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 160 + padding,
            name: 'Planted acres',
            key: 'planting_a',
            sortable: true,
            headerRenderer: HeaderCell
        }
    ];

    const NumberFormatter = (value) => {
        return <span>{formatNumber(value, 0)} </span>;
    };

    const PriceFormatter = (value) => {
        return <span>${formatPrice(value)} </span>;
    };

    const FillFormatter = (data) => {
        return (
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    lineHeight: '27px',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <Filled value={data.row.efficiency} min={range[0]} max={range[1]} projectRank={data.row.sorted_id} totalProjects={rows.length} />
            </div>
        );
    };

    const SelectFormatter = (data) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Checkbox
                    style={{ padding: '0px', margin: 'auto' }}
                    color='primary'
                    checked={selected.has(data.row.sorted_id)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />

                <Tooltip title='Locate'>
                    <IconButton
                        onClick={() => handleLocateClick(data)}
                        style={{ padding: 0, margin: 'auto' }}
                        aria-label='sort'
                    >
                        <PinDropIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const handleRowClick = (index, data) => {
        setSelected((state) => {
            if (state.has(data.sorted_id)) {
                setCost(cost - data.cost);
                setSolarLoadImpact(solarLoadImpact - data.uplift);
                return new Set([...state].filter((x) => x !== data.sorted_id));
            } else {
                setCost(cost + data.cost);
                setSolarLoadImpact(solarLoadImpact + data.uplift);
                return new Set(state).add(data.sorted_id);
            }
        });
    };

    const handleLocateClick = (data) => {
        activateFeature(map, 'shade-layer', data.row.coordinates, data.row);
        history.push('/map');
        setNavBarSync(true);
    };

    const sort = (value) => {
        setRows(sortDirection === 'ASC' ? sortBy(rows, value).reverse() : sortBy(rows, value));
        setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        setSortColumn(value);
    };

    return (
        <div className={classes.root}>
            <TableHeader
                map={map}
                title={'Solar Load Reduction Projects'}
                info={[
                    { name: 'Blocked solar load (kcals)', value: formatNumber(solarLoadImpact, 0) },
                    { name: 'Cost', value: formatPrice(cost) }
                ]}
                data={data}
                rows={rows}
                setRows={setRows}
                selected={selected}
                handleAdd={() => setOpen(true)}
            />

            <div className={classes.grid}>
                <TableLoading loading={data === null} empty={rows.length === 0} />
                <DataGrid
                    rowKey='id'
                    columns={columns}
                    rows={rows}
                    onRowClick={handleRowClick}
                    headerRowHeight={50}
                    rowHeight={38}
                />
            </div>

            <TableModal
                type='shade'
                user={user}
                selected={selected}
                setSelected={setSelected}
                open={open}
                setOpen={setOpen}
                onAdd={() => {
                    setCost(0);
                    setSolarLoadImpact(0);
                }}
            />
        </div>
    );
};

export default ShadeTable;
