import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTokenStore } from './useTokenStore';

export const useVerifyLogIn = () => {
    const history = useHistory();
    const location = useLocation();
    const hasTokens = useTokenStore((s) => !!(s.accessToken && s.refreshToken));

    useEffect(() => {
        if (!hasTokens && location.pathname !== '/') {
            history.push('/');
        }
        console.log('Has Tokens', hasTokens);
    }, [hasTokens, location]);

    return hasTokens;
};
