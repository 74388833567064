import React from 'react';

import LoginAlert from './LoginAlert';
import AuthenticatedAlert from './AuthenticatedAlert';
import LogoutAlert from './LogoutAlert';
import BlockedAlert from './BlockedAlert';

const Alert = (props) => {
    const { status, setNavBarSync } = props;

    if (status === 'login') {
        return <LoginAlert />;
    }

    if (status === 'authenticated') {
        return <AuthenticatedAlert setNavBarSync={setNavBarSync} />;
    }

    if (status === 'terminated') {
        return <LogoutAlert />;
    }

    if (status === 'blocked') {
        return <BlockedAlert />;
    }

    return null;
};

export default Alert;
