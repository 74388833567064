import React from 'react';
import { useVerifyLogIn } from './useVerifyLogIn';

const WaitForAuth = ({ children }) => {
    if (!useVerifyLogIn()) {
        return null;
    }

    return <>{children}</>;
};

export default WaitForAuth;
