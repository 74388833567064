import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import Draggable from 'react-draggable';
import { BiWater } from 'react-icons/bi';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        padding: 8,
        minWidth: 300,
        top: '2%',
        left: '2%',
        zIndex: 2
    },
    textWrapper: {
        display: 'flex',
        alignItems: 'center'
    }
});

const WaterShed = (props) => {
    const classes = useStyles();
    const { map, hoveredWatershed, hoveredSubwater, hoveredTaxlot, showTaxlot } = props;

    return (
        <Draggable>
            <Card className={classes.root}>
                <div className={classes.textWrapper}>
                    <BiWater style={{ fontSize: '1.2rem', color: '#4a94ff' }} />
                    <Typography variant='body1' style={{ fontWeight: 600, margin: 4 }}>
                        Watershed:
                    </Typography>
                    <Typography>{hoveredWatershed}</Typography>
                </div>

                <div className={classes.textWrapper}>
                    <BiWater style={{ fontSize: '1.2rem', color: '#4a94ff' }} />
                    <Typography variant='body1' style={{ fontWeight: 600, margin: 4 }}>
                        Subwatershed:
                    </Typography>
                    <Typography>{hoveredSubwater}</Typography>
                </div>

                {showTaxlot && (
                    <div className={classes.textWrapper}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            version='1.1'
                            height='22'
                            width='22'
                            viewBox='0 0 32 32'
                        >
                            <path
                                d='m14.656 6.88l-14.656 9.984 9.952 4.96 12.48-12.256-7.776-2.688zm8.416 2.88l-7.424 7.584 7.84 6.304 8.544-10.752-8.96-3.136zm-7.872 8.064l-4.48 4.352 9.952 4.96 2.4-2.816-7.872-6.496z'
                                fill='#c62a0c'
                            />
                        </svg>

                        <Typography variant='body1' style={{ fontWeight: 600, margin: 4 }}>
                            Parcel#:
                        </Typography>
                        <Typography>{hoveredTaxlot}</Typography>
                    </div>
                )}
            </Card>
        </Draggable>
    );
};

export default WaterShed;
