import React, { createContext, useState } from 'react';
const context = createContext(null);

const AlertProvider = ({ children }) => {
    const [state, setAlert] = useState({ status: '' });
    const value = { state: state, setAlert: setAlert };

    return <context.Provider value={value}>{children}</context.Provider>;
};

AlertProvider.context = context;

export default AlertProvider;
