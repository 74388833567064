import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import Fab from '@material-ui/core/Fab';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        position: 'absolute',
        zIndex: '2',
        right: '2%',
        bottom: '14%',
        color: 'rgba(0, 0, 0, 0.76)'
    },
    fab: {
        color: 'rgba(0, 0, 0, 0.76)'
    },
    filterContainer: {
        display: 'flex',
        padding: theme.spacing(2),
        width: '300px'
    },
    formGroup: {
        width: '90%',
        color: 'rgba(0, 0, 0, 0.73)'
    },
    formLabel: {
        margin: '0 0 1.25em 14px'
    },
    listWrapper: {
        borderLeft: '1px dashed rgba(0, 0, 0, 0.4)',
        paddingLeft: '18px',
        marginLeft: '9px',
        listStyle: 'none'
    },
    checkbox: {
        padding: '5px 5px 5px 8px',
        color: 'rgb(87, 106, 207) !important'
    },
    text: {
        fontSize: '0.95rem'
    }
}));

const watersheds = [
    'Upper Rogue',
    'Antelope Creek',
    'Elk Creek',
    'Little Butte & North Fork Little Butte',
    'South Fork Little Butte',
    'Other Upper Rogue',
    'Illinois River',
    'East Fork Illinois River',
    'Sucker Creek',
    'Other Illinois River',
    'Middle Rogue',
    'Evans Creek',
    'Other Middle Rogue',
    'Lower Rogue',
    'Other Lower Rogue',
    'Applegate River',
    'Other Applegate River'
];

// if all dependecies are false then false
const dependencies = {
    'Upper Rogue': [1, 5],
    'Illinois River': [7, 9],
    'Middle Rogue': [11, 12],
    'Lower Rogue': [14, 14],
    'Applegate River': [16, 16]
};

const SubwaterFilter = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const { watershed, setWatershed, subwater, setSubwater } = props;

    // toogle checkbox
    const handleChange = (event) => {
        setWatershed({ ...watershed, [event.target.name]: event.target.checked });
        setSubwater({ ...subwater, [event.target.name]: event.target.checked });
    };

    const checkDependecies = (key) => {
        if (key === 'All watersheds') {
            // check all watersheds and their dependencies
            for (let w in dependencies) {
                for (let i = dependencies[w][0]; i <= dependencies[w][1]; i++) {
                    if (subwater[watersheds[i]] && watershed[w]) {
                        return true;
                    }
                }
            }
            return false;
        }
        // check watershed and its dependencies
        for (let i = dependencies[key][0]; i <= dependencies[key][1]; i++) {
            if (watershed[watersheds[i]] || subwater[watersheds[i]]) {
                return true;
            }
        }
        return false;
    };

    return (
        <div className={classes.buttonContainer}>
            <Fab className={classes.fab} onClick={(event) => setAnchorEl(event.currentTarget)}>
                <SettingsRoundedIcon />
            </Fab>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <div className={classes.filterContainer}>
                    <FormGroup className={classes.formGroup}>
                        <ul style={{ paddingLeft: '6px', listStyle: 'none', marginTop: '0' }}>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                watershed['All watersheds'] &&
                                                checkDependecies('All watersheds')
                                            }
                                            onChange={handleChange}
                                            name='All watersheds'
                                            className={classes.checkbox}
                                        />
                                    }
                                    label={
                                        <Typography className={classes.text}>
                                            Rogue River Basin
                                        </Typography>
                                    }
                                />

                                <ul className={classes.listWrapper}>
                                    <li>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        watershed['Upper Rogue'] &&
                                                        watershed['All watersheds'] &&
                                                        checkDependecies('Upper Rogue')
                                                    }
                                                    onChange={handleChange}
                                                    name='Upper Rogue'
                                                    className={classes.checkbox}
                                                />
                                            }
                                            label={
                                                <Typography className={classes.text}>
                                                    Upper Rogue
                                                </Typography>
                                            }
                                        />
                                    </li>
                                    <ul className={classes.listWrapper}>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Antelope Creek'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Upper Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Antelope Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Antelope Creek
                                                    </Typography>
                                                }
                                            />
                                        </li>

                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Elk Creek'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Upper Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Elk Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Elk Creek
                                                    </Typography>
                                                }
                                            />
                                        </li>

                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater[
                                                                'Little Butte & North Fork Little Butte Creek'
                                                            ] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Upper Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Little Butte & North Fork Little Butte Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Little Butte & N. F. Little Butte Creek
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater[
                                                                'South Fork Little Butte Creek'
                                                            ] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Upper Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='South Fork Little Butte Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        S. F. Little Butte Creek
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Other Upper Rogue'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Upper Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Other Upper Rogue'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Other
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                    </ul>

                                    <li>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        watershed['Illinois River'] &&
                                                        watershed['All watersheds'] &&
                                                        checkDependecies('Illinois River')
                                                    }
                                                    onChange={handleChange}
                                                    name='Illinois River'
                                                    className={classes.checkbox}
                                                />
                                            }
                                            label={
                                                <Typography className={classes.text}>
                                                    Illinois
                                                </Typography>
                                            }
                                        />
                                    </li>

                                    <ul className={classes.listWrapper}>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['East Fork Illinois River'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Illinois River']
                                                        }
                                                        onChange={handleChange}
                                                        name='East Fork Illinois River'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        East Fork Illinois River
                                                    </Typography>
                                                }
                                            />
                                        </li>

                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Sucker Creek'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Illinois River']
                                                        }
                                                        onChange={handleChange}
                                                        name='Sucker Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Sucker Creek
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Other Illinois River'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Illinois River']
                                                        }
                                                        onChange={handleChange}
                                                        name='Other Illinois River'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Other
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                    </ul>
                                    <li>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        watershed['Middle Rogue'] &&
                                                        watershed['All watersheds'] &&
                                                        checkDependecies('Middle Rogue')
                                                    }
                                                    onChange={handleChange}
                                                    name='Middle Rogue'
                                                    className={classes.checkbox}
                                                />
                                            }
                                            label={
                                                <Typography className={classes.text}>
                                                    Middle Rogue
                                                </Typography>
                                            }
                                        />
                                    </li>
                                    <ul className={classes.listWrapper}>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Evans Creek'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Middle Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Evans Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Evans Creek
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Other Middle Rogue'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Middle Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Other Middle Rogue'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Other
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                    </ul>
                                    <li>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        watershed['Lower Rogue'] &&
                                                        watershed['All watersheds'] &&
                                                        checkDependecies('Lower Rogue')
                                                    }
                                                    onChange={handleChange}
                                                    name='Lower Rogue'
                                                    className={classes.checkbox}
                                                />
                                            }
                                            label={
                                                <Typography className={classes.text}>
                                                    Lower Rogue
                                                </Typography>
                                            }
                                        />
                                    </li>
                                    <ul className={classes.listWrapper}>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Other Lower Rogue'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Lower Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Other Lower Rogue'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Other
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                    </ul>

                                    <li>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        watershed['Applegate River'] &&
                                                        watershed['All watersheds'] &&
                                                        checkDependecies('Applegate River')
                                                    }
                                                    onChange={handleChange}
                                                    name='Applegate River'
                                                    className={classes.checkbox}
                                                />
                                            }
                                            label={
                                                <Typography className={classes.text}>
                                                    Applegate River
                                                </Typography>
                                            }
                                        />
                                    </li>
                                    <ul className={classes.listWrapper}>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Other Applegate River'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Applegate River']
                                                        }
                                                        onChange={handleChange}
                                                        name='Other Applegate River'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Other
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                    </ul>
                                </ul>
                            </li>
                        </ul>
                    </FormGroup>
                </div>
            </Popover>
        </div>
    );
};

export default SubwaterFilter;
