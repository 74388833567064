import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DataGrid from 'react-data-grid';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputBase from '@material-ui/core/InputBase';
import useWindowDimensions from '../Dimensions/WindowDimensions';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ProgramModal from '../Programs/ProgramModal';
import TableLoading from './Loading';
import { useApi } from '../../Api';
import { sortBy } from 'underscore';
import { formatNumber, formatPrice } from '../lib/formatlib';
import { CSVLink } from 'react-csv';
import styles from './style.css';

const useStyles = makeStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        zIndex: 3
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '84px',
        padding: '0 24px 0 24px',
        color: 'rgba(0, 0, 0, 0.67)'
    },
    tableOptions: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center'
    },
    grid: {
        height: '100%',
        overflowY: 'hidden',
        position: 'relative'
    },
    headerCell: {
        color: '#3c3b3f',
        fontSize: ' 0.875rem',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        '&:after': {
            content: "''",
            position: 'absolute',
            borderLeft: '2px solid rgb(220, 227, 233)',
            top: '35%',
            left: '0',
            height: '40%',
            marginTop: 'auto',
            marginBottom: 'auto'
        }
    }
});

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        color: '#68696c',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        }
    }
}))(InputBase);

const createData = (data) => {
    const roundString = (value) => {
        return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100;
    };

    const formatDate = (date) => {
        return date.substring(0, 10);
    };

    data.forEach((value) => {
        value['heat_uplift'] = roundString(value.heat_uplift);
        value['ntt_uplift'] = roundString(value.ntt_uplift);
        value['graip_uplift'] = roundString(value.graip_uplift);
        value['shade_uplift'] = roundString(value.shade_uplift);
        value['program_cost'] = parseFloat(value.program_cost);
        value['date_created'] = formatDate(value.date_created);
    });

    return data;
};

const ProgramTable = (props) => {
    const api = useApi();
    const classes = useStyles();
    const { height, width } = useWindowDimensions();
    const { map, data, mode, setMode, setUpdate, setNavBarSync } = props;

    const [rows, setRows] = useState([]);
    const [row, setRow] = useState({});
    const [open, setOpen] = useState(false);

    const [sortColumn, setSortColumn] = useState('program_cost');
    const [sortDirection, setSortDirection] = useState('DESC');

    let padding = width > 1280 ? (width - 1280 - 5) / 7 : 0;

    useEffect(() => {
        if (data !== null) {
            setRows(createData(data));
        }
    }, [data]);

    const ActionCell = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    color: '#3c3b3f',
                    fontSize: ' 0.875rem',
                    fontWeight: 500
                }}
            >
                {mode === 'personal' && (
                    <Tooltip title='Delete'>
                        <IconButton style={{ padding: 0, margin: 'auto' }} aria-label='sort'>
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title='Inspect'>
                    <IconButton style={{ padding: 0, margin: 'auto' }} aria-label='sort'>
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const HeaderCell = ({ column }) => {
        return (
            <div className={classes.headerCell}>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={column.sortable ? () => sort(column.key) : () => {}}
                >
                    {column.name}
                </span>

                {sortColumn === column.key && sortDirection === 'ASC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowUpwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                {sortColumn === column.key && sortDirection === 'DESC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowDownwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}
            </div>
        );
    };

    const columns = [
        {
            width: 120,
            name: 'Select',
            key: 'Select',
            formatter: (data) => ActionFormatter(data),
            headerRenderer: ActionCell
        },
        {
            width: 160 + padding,
            name: 'Program Name',
            key: 'program_name',
            sortable: true,
            headerRenderer: HeaderCell
        },

        {
            width: 160 + padding,
            name: 'Program Cost ($)',
            key: 'program_cost',
            formatter: (data) => PriceFormatter(data.row.program_cost),
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 210 + padding,
            name: 'Flow Temp. Impact (deg*hrs)',
            key: 'flow_uplift',
            formatter: (data) => NumberFormatter(data.row.flow_uplift),
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 210 + padding,
            name: 'Stream Temp. Impact (deg*hrs)',
            key: 'heat_uplift',
            formatter: (data) => NumberFormatter(data.row.heat_uplift),
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 170 + padding,
            name: 'Field Sed. Uplift (tons/yr)',
            key: 'ntt_uplift',
            formatter: (data) => NumberFormatter(data.row.ntt_uplift),
            sortable: true,
            headerRenderer: HeaderCell
        },

        {
            width: 170 + padding,
            name: 'Road Sed. Uplift (kg/yr)',
            key: 'graip_uplift',
            formatter: (data) => NumberFormatter(data.row.graip_uplift),
            sortable: true,
            headerRenderer: HeaderCell
        },

        {
            width: 170 + padding,
            name: 'Solar Load Blocked (kcals)',
            key: 'shade_uplift',
            formatter: (data) => NumberFormatter(data.row.shade_uplift),
            sortable: true,
            headerRenderer: HeaderCell
        },

        {
            width: 120 + padding,
            name: 'Date Created',
            key: 'date_created',
            sortable: true,
            headerRenderer: HeaderCell
        }
    ];

    const handleClick = (row) => {
        setOpen(true);
        setRow(row);
    };

    const handleDelete = (row) => {
        const response = api.deleteProgram(row.program_id, row.user_id);
        response.then((value) => {
            setUpdate(true);
        });
    };

    const ActionFormatter = (data) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                {mode === 'personal' && (
                    <Tooltip title='Delete'>
                        <IconButton
                            onClick={() => handleDelete(data.row)}
                            style={{ padding: 0, margin: 'auto' }}
                            aria-label='sort'
                        >
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title='Inspect'>
                    <IconButton
                        onClick={() => handleClick(data.row)}
                        style={{ padding: 0, margin: 'auto' }}
                        aria-label='sort'
                    >
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const NumberFormatter = (value) => {
        return <span>{formatNumber(value, 1)} </span>;
    };

    const PriceFormatter = (value) => {
        return <span>${formatPrice(value)} </span>;
    };

    const sort = (value) => {
        setRows(sortDirection === 'ASC' ? sortBy(rows, value).reverse() : sortBy(rows, value));
        setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        setSortColumn(value);
    };

    return (
        <div className={classes.root}>
            <div className={classes.tableHeader}>
                <Typography
                    style={{ fontSize: '1.1rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.87)' }}
                >
                    {mode === 'personal' ? 'My programs' : 'Public programs'}
                </Typography>

                <div className={classes.tableOptions}>
                    <FormControl className={classes.margin}>
                        <Select
                            labelId='program-type'
                            className={classes.selectBox}
                            id='program-type'
                            value={mode}
                            onChange={(event) => setMode(event.target.value)}
                            input={<BootstrapInput />}
                        >
                            <MenuItem value='personal'>My programs</MenuItem>
                            <MenuItem value='public'>Public programs</MenuItem>
                        </Select>
                    </FormControl>

                    <Tooltip title='Export'>
                        <CSVLink data={rows}>
                            <IconButton aria-label='Export'>
                                <SaveAltIcon />
                            </IconButton>
                        </CSVLink>
                    </Tooltip>
                </div>
            </div>

            <div className={classes.grid}>
                <TableLoading loading={data === null} empty={rows.length === 0} />
                <DataGrid
                    rowKey='id'
                    columns={columns}
                    rows={rows}
                    headerRowHeight={50}
                    rowHeight={38}
                />
            </div>

            <ProgramModal
                map={map}
                open={open}
                setOpen={setOpen}
                program={row}
                setProgram={setRow}
                setNavBarSync={setNavBarSync}
                mode={mode}
            />
        </div>
    );
};

export default ProgramTable;
