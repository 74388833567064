import React from 'react';
import Root from './Root/Root';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    app: {
        height: '100%',
        width: '100%'
    }
}));

const theme = createMuiTheme({
    typography: {
        fontFamily: ['Roboto'].join(',')
    }
});

function App() {
    const classes = useStyles();
    return (
        <div className={classes.app}>
            <ThemeProvider theme={theme}>
                <Root />
            </ThemeProvider>
        </div>
    );
}

export default App;
