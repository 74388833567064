import axios from 'axios';
import { useTokenStore } from '../auth/useTokenStore';

export const useSecureRequest = () => {
    const serverAddress = process.env.REACT_APP_PUBLIC_SERVER_ADDRESS;
    const { accessToken, refreshToken, setTokens } = useTokenStore.getState();

    const JWTConfig = (accessToken) => ({
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    const GETRequest = async (endpoint, data, _accessToken) => {
        try {
            const response = await axios.get(serverAddress + endpoint, {
                ...JWTConfig(_accessToken || accessToken),
                params: data
            });
            return response;
        } catch (err) {
            console.log(err);
            return {};
        }
    };

    const POSTRequest = async (endpoint, data, _accessToken) => {
        try {
            const response = await axios.post(
                serverAddress + endpoint,
                data,
                JWTConfig(_accessToken || accessToken)
            );
            return response;
        } catch (err) {
            console.log(err);
            return {};
        }
    };

    const getRefreshToken = async () => {
        try {
            const response = await axios.get(serverAddress + '/auth/refresh', {
                params: { refreshToken }
            });
            return response.data.accessToken;
        } catch (err) {
            console.log(err);
            return '';
        }
    };

    const secureRequest = async ({ endpoint, type, data }) => {
        let response;

        switch (type) {
            case 'GET':
                response = await GETRequest(endpoint, data);
                if (response.status !== 200) {
                    const _accessToken = await getRefreshToken();
                    response = await GETRequest(endpoint, data, _accessToken);
                    setTokens({ refreshToken, accessToken: _accessToken });
                }
                break;
            case 'POST':
                response = await POSTRequest(endpoint, data);
                if (response.status !== 200) {
                    const _accessToken = await getRefreshToken();
                    response = await POSTRequest(endpoint, data, _accessToken);
                    setTokens({ refreshToken, accessToken: _accessToken });
                }
                break;
        }

        return response;
    };

    return { secureRequest };
};
