import React, { useState, useEffect, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useApi } from '../../Api';
import { initializeMap, addSource, addLayer, addHover, addClick } from '../lib/maplib';
import Filters from '../Filters/Filters';

const useStyles = makeStyles((theme) => ({
    mapContainer: {
        flex: 1,
        height: '100%'
    }
}));

const Map = (props) => {
    const classes = useStyles();
    const mapContainer = createRef();

    const { map, setMap } = props;
    const { loading } = props;
    const { resizeMap, setResizeMap } = props;

    const api = useApi();

    // Hovered layers
    const [hoveredWatershed, setHoveredWatershed] = useState('');
    const [hoveredSubwater, setHoveredSubwater] = useState('');
    const [hoveredTaxlot, setHoveredTaxlot] = useState('');

    // geographical project data
    const {
        hsData,
        flowData,
        hsFlowData,
        shadeData,
        nttData,
        graipData,
        watershedData,
        subwaterData,
        culvertData,
        taxlotData
    } = props;
    const {
        setHs,
        setFlow,
        setHsFlow,
        setShade,
        setNtt,
        setGraip,
        setWatershed,
        setSubwater,
        setCulvert,
        setTaxlot
    } = props;

    // initialize map
    useEffect(() => {
        if (!map) initializeMap({ setMap, mapContainer });
    }, [map]);

    // fetch data
    useEffect(() => {
        if (map) {
            api.fetchHsData(hsCallBack);
            api.fetchFlowData(flowCallBack);
            api.fetchHsFlowData(hsFlowCallBack);
            api.fetchShadeData(shadeCallBack);
            api.fetchNttData(nttCallBack);
            api.fetchGraipData(graipCallBack);
            api.fetchWatershedData(watershedCallBack);
            api.fetchSubwaterData(subwaterCallBack);
            api.fetchCulvertData(culvertCallBack);
            api.fetchTaxlotData(taxlotCallBack);
        }
    }, [map]);

    useEffect(() => {
        if (resizeMap) {
            map.resize();
            setResizeMap(!resizeMap);
        }
    }, [resizeMap, setResizeMap]);

    const hsCallBack = (data) => {
        setHs(data);
        addSource(map, 'hs-data', 'geojson', data);
        addLayer(map, 'hs-layer', 'hs-data', 'circle', {
            'circle-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'hs_veg_sorted_id']],
                0,
                '#002b80',
                40,
                '#026fd6',
                100,
                '#2086e6',
                160,
                '#349beb'
            ],
            'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.8],
            'circle-radius': ['step', ['zoom'], 7, 10, 10, 12, 12]
        });
        map.addLayer({
            id: 'hs-layer-rank',
            type: 'symbol',
            source: 'hs-data',
            layout: {
                'text-field': '{hs_veg_sorted_id}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 14
            },
            minzoom: 12,
            paint: {
                'text-color': '#fff'
            }
        });
        addClick(map, 'hs-layer');
        addHover(map, 'hs-layer', 'hs-data');
    };

    const flowCallBack = (data) => {
        setFlow(data);
        addSource(map, 'flow-data', 'geojson', data);
        addLayer(map, 'flow-layer', 'flow-data', 'circle', {
            'circle-color': 'purple',
            'circle-radius': 5,
            'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.99]
        });
        addClick(map, 'flow-layer');
        addHover(map, 'flow-layer', 'flow-data');
    };

    const hsFlowCallBack = (data) => {
        setHsFlow(data);
        addSource(map, 'hs-flow-data', 'geojson', data);
        addLayer(map, 'hs-flow-layer', 'hs-flow-data', 'line', {
            'line-color': 'green',
            'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.6],
            'line-width': 3
        });
        addClick(map, 'hs-flow-layer');
        addHover(map, 'hs-flow-layer', 'hs-flow-data');
    };

    const shadeCallBack = (data) => {
        setShade(data);
        addSource(map, 'shade-data', 'geojson', data);
        addLayer(map, 'shade-layer', 'shade-data', 'fill', {
            'fill-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'sorted_id']],
                0,
                'red',
                450,
                'yellow',
                890,
                'green'
            ]
        });
        map.addLayer({
            id: 'shade-layer-rank',
            type: 'symbol',
            source: 'shade-data',
            layout: {
                'text-field': '{sorted_id}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 14
            },
            minzoom: 12
        });
        addClick(map, 'shade-layer');
        addHover(map, 'shade-layer', 'shade-data');
    };

    const nttCallBack = (data) => {
        setNtt(data);
        addSource(map, 'ntt-data', 'geojson', data);
        addLayer(map, 'ntt-layer', 'ntt-data', 'fill', {
            'fill-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'ntt_sorted_id']],
                0,
                '#7f2704',
                200,
                '#a63603',
                600,
                '#d94801',
                1200,
                '#f16913',
                2000,
                '#fd8d3c',
                3000,
                '#fdae6b',
                4000,
                '#fdd0a2'
            ],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.6]
        });
        map.addLayer({
            id: 'ntt-layer-rank',
            type: 'symbol',
            source: 'ntt-data',
            layout: {
                'text-field': '{ntt_sorted_id}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 14
            },
            minzoom: 12,
            paint: {
                'text-color': '#fff'
            },

            filter: ['!=', ['get', 'scenario_text'], 'Current conditions']
        });
        addClick(map, 'ntt-layer');
        addHover(map, 'ntt-layer', 'ntt-data');
    };

    const graipCallBack = (data) => {
        setGraip(data);
        addSource(map, 'graip-data', 'geojson', data);
        addLayer(map, 'graip-layer', 'graip-data', 'line', {
            'line-color': [
                'interpolate',
                ['linear'],
                ['to-number', ['get', 'graip_sorted_id']],
                0,
                '#2a1503',
                1600,
                '#3c1e08',
                3200,
                '#7b481c',
                5400,
                '#9a7b4d'
            ],
            'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 1],
            'line-width': 3
        });
        addClick(map, 'graip-layer');
        addHover(map, 'graip-layer', 'graip-data');
    };

    const watershedCallBack = (data) => {
        setWatershed(data);
        addSource(map, 'watershed-data', 'geojson', data);
        addLayer(map, 'watershed-layer', 'watershed-data', 'fill', {
            'fill-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                'rgba(197, 195, 195, 0.15)',
                'rgba(0,0,0,0)'
            ],

            'fill-opacity': ['step', ['zoom'], 0.6, 10, 0],
            'fill-outline-color': '#991e02'
        });
        addHover(
            map,
            'watershed-layer',
            'watershed-data',
            (props) => {
                setHoveredWatershed(props.name);
            },
            () => {
                setHoveredWatershed('');
            }
        );
    };

    const subwaterCallBack = (data) => {
        setSubwater(data);
        addSource(map, 'subwater-data', 'geojson', data);
        addLayer(map, 'subwater-layer', 'subwater-data', 'fill', {
            'fill-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                'rgba(197, 195, 195, 0.15)',
                'rgba(0,0,0,0)'
            ],
            'fill-opacity': ['step', ['zoom'], 0.6, 10, 0],
            'fill-outline-color': '#991e02'
        });
        addHover(
            map,
            'subwater-layer',
            'subwater-data',
            (props) => {
                setHoveredSubwater(props.name);
            },
            () => {
                setHoveredSubwater('');
            }
        );
    };

    const culvertCallBack = (data) => {
        setCulvert(data);
        addSource(map, 'culvert-data', 'geojson', data);
        addLayer(map, 'culvert-layer', 'culvert-data', 'circle', {
            'circle-color': 'red',
            'circle-radius': 4,
            'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.99]
        });
        addClick(map, 'culvert-layer');
        addHover(map, 'culvert-layer', 'culvert-data');
    };

    const taxlotCallBack = (data) => {
        setTaxlot(data);
        addSource(map, 'taxlot-data', 'geojson', data);
        addLayer(map, 'taxlot-layer', 'taxlot-data', 'line', {
            'line-color': '#c62a0c',
            'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 3, 1]
        });
        addLayer(map, 'taxlot-layer-fill', 'taxlot-data', 'fill', {
            'fill-color': '#c62a0c',
            'fill-opacity': 0
        });
        addHover(
            map,
            'taxlot-layer-fill',
            'taxlot-data',
            (props) => {
                setHoveredTaxlot(props.parcel_num);
            },
            () => {
                setHoveredTaxlot('');
            }
        );
    };

    return (
        <div
            ref={mapContainer}
            className={classes.mapContainer}
            style={{ filter: loading ? 'blur(8px)' : 'none' }}
        >
            <Filters
                map={map}
                hsData={hsData}
                flowData={flowData}
                hsFlowData={hsFlowData}
                shadeData={shadeData}
                nttData={nttData}
                graipData={graipData}
                watershedData={watershedData}
                subwaterData={subwaterData}
                culvertData={culvertData}
                taxlotData={taxlotData}
                hoveredSubwater={hoveredSubwater}
                hoveredWatershed={hoveredWatershed}
                hoveredTaxlot={hoveredTaxlot}
            />
        </div>
    );
};

export default Map;
