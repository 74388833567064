import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import DataGrid from 'react-data-grid';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import TimelineIcon from '@material-ui/icons/Timeline';
import { CSVLink } from 'react-csv';
import TableLoading from './Loading';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { activateFeature } from '../lib/maplib';
import { sortBy } from 'underscore';
import { useApi } from '../../Api';
import styles from './style.css';

const useStyles = makeStyles({
    root: {
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        background: '#fff',
        zIndex: 3
    },
    headerCell: {
        color: '#3c3b3f',
        fontSize: ' 0.875rem',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        '&:after': {
            content: "''",
            position: 'absolute',
            borderLeft: '2px solid rgb(220, 227, 233)',
            top: '35%',
            left: '0',
            height: '40%',
            marginTop: 'auto',
            marginBottom: 'auto'
        }
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '64px',
        padding: '0 8px 0 24px',
        color: 'rgba(0, 0, 0, 0.67)'
    },
    tableOptions: {
        display: 'flex',
        marginLeft: 'auto'
    },
    grid: {
        height: '100%',
        overflowY: 'hidden',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    }
});

const ProjectsTable = (props) => {
    const api = useApi();
    const classes = useStyles();
    const history = useHistory();
    const { data, map, title, setVisual, mode, setGraphPath, setNavBarSync, update, setUpdate } =
        props;

    const [rows, setRows] = useState([]);
    const [sortColumn, setSortColumn] = useState('efficiency');
    const [sortDirection, setSortDirection] = useState('DESC');

    useEffect(() => {
        if (data !== null) {
            setRows(data);
        }
    }, [data]);

    const createColumns = (width) => {
        const columns = [
            {
                width: width / 4,
                name: 'Project type',
                key: 'project type',
                sortable: true,
                headerRenderer: HeaderCell
            },
            {
                width: width / 4,
                name: 'Watershed',
                key: 'watershed',
                sortable: true,
                headerRenderer: HeaderCell
            },
            {
                width: mode === 'personal' ? width / 6 : width / 4,
                name: 'Locate',
                key: 'locate',
                formatter: (data) => LocateRender(data),
                headerRenderer: HeaderCell
            },
            {
                width: mode === 'personal' ? width / 6 : width / 4,
                name: 'Visual',
                key: 'visual',
                formatter: (data) => GraphRender(data),
                headerRenderer: HeaderCell
            }
        ];

        if (mode === 'personal') {
            columns.push({
                width: width / 6,
                name: 'Delete',
                key: 'delete',
                formatter: (data) => DeleteRender(data),
                headerRenderer: HeaderCell
            });
        }
        return columns;
    };

    const HeaderCell = ({ column }) => {
        return (
            <div className={classes.headerCell}>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={column.sortable ? () => sort(column.key) : () => { }}
                >
                    {column.name}
                </span>

                {sortColumn === column.key && sortDirection === 'ASC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowUpwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                {sortColumn === column.key && sortDirection === 'DESC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowDownwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}
            </div>
        );
    };

    const GraphRender = ({ row }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                {row.project === 'heat' ? (
                    <Tooltip title='Graph' aria-label='graph'>
                        <IconButton onClick={() => handleVisual(row)} aria-label='graph'>
                            <TimelineIcon style={{ color: '#007afc' }} />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>
        );
    };

    const DeleteRender = ({ row }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Tooltip title='Delete' aria-label='delete'>
                    <IconButton onClick={() => handleDelete(row)} aria-label='delete'>
                        <DeleteOutlineIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const LocateRender = ({ row }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Tooltip title='Locate' aria-label='locate'>
                    <IconButton onClick={() => handleLocate(row)} aria-label='locate'>
                        <PinDropIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const handleDelete = (rowData) => {
        api.deleteProject(rowData.project, rowData.projectId)
            .then(() => {
                setUpdate(update ? false : true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleLocate = (rowData) => {
        activateFeature(map, `${rowData.project}-layer`, rowData.coordinates, rowData);
        history.push('/map');
        setNavBarSync(true);
    };

    const handleVisual = (rowData) => {
        const serverAddress = process.env.REACT_APP_PUBLIC_SERVER_ADDRESS;
        setGraphPath(
            `${serverAddress}/3d_plots/${rowData.stream_acronym}_${rowData.proj_name}.jpg`
        );
        setVisual(true);
    };

    const sort = (value) => {
        setRows(sortDirection === 'ASC' ? sortBy(rows, value).reverse() : sortBy(rows, value));
        setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        setSortColumn(value);
    };

    return (
        <div className={classes.root}>
            <div className={classes.tableHeader}>


                <Typography
                    style={{ fontSize: '1.1rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.87)' }}
                >
                    {title}
                </Typography>


                <div className={classes.tableOptions}>
                    <Tooltip title='Export'>
                        <CSVLink data={rows}>
                            <IconButton aria-label='Export'>
                                <SaveAltIcon />
                            </IconButton>
                        </CSVLink>
                    </Tooltip>
                </div>
            </div>

            <div className={classes.grid}>
                <TableLoading loading={data === null} empty={rows.length === 0} />
                <DataGrid
                    style={{ flex: 1 }}
                    rowKey='id'
                    columns={createColumns(795)}
                    rows={rows}
                    headerRowHeight={50}
                    rowHeight={38}
                />
            </div>
        </div>
    );
};

export default ProjectsTable;
