/*
 * Mapbox auxilary functions.
 */
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { roundString, formatDate, formatPrice } from './formatlib';

mapboxgl.accessToken =
    'pk.eyJ1Ijoid2lsbGNhcnRlciIsImEiOiJjamV4b2g3Z2ExOGF4MzFwN3R1dHJ3d2J4In0.Ti-hnuBH8W4bHn7k6GCpGw';

/* Initialize map inside DOM element <mapContainer> */
export const initializeMap = ({ setMap, mapContainer }) => {
    const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v9',
        center: [-123.06, 42.418],
        zoom: 8.5
    });
    map.on('load', () => {
        setMap(map);
        map.resize();
        map.addControl(new mapboxgl.NavigationControl(), 'top-right');
        map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
    });
};

/* Add source in map */
export const addSource = (map, source, type, data) => {
    try {
        map.addSource(source, {
            type: type,
            data: data,
            promoteId: 'id'
        });
    } catch (err) {
        console.log(err);
    }
};

/* Add layer in map */
export const addLayer = (map, id, source, type, paint) => {
    try {
        map.addLayer({
            id: id,
            source: source,
            type: type,
            paint: paint
        });
    } catch (err) {
        console.log(err);
    }
};

/* Add popup functionality on a specific map layer */
export const addClick = (map, layerId) => {
    map.on('click', layerId, function (e) {
        new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(Popup(e.features[0].properties, layerId))
            .addTo(map);
    });

    map.on('mouseenter', layerId, function () {
        map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', layerId, function () {
        map.getCanvas().style.cursor = '';
    });
};

/* Add hover functionality on layer features */
export const addHover = (map, layerId, sourceId, onEnter = () => {}, onLeave = () => {}) => {
    let hoveredStateId = null;

    map.on('mousemove', layerId, function (e) {
        if (e.features.length > 0) {
            if (hoveredStateId) {
                map.setFeatureState({ source: sourceId, id: hoveredStateId }, { hover: false });
            }
            hoveredStateId = e.features[0].properties.id; // featureId is an optional param
            map.setFeatureState({ source: sourceId, id: hoveredStateId }, { hover: true });
            onEnter(e.features[0].properties);
        }
    });

    map.on('mouseleave', layerId, function () {
        if (hoveredStateId) {
            map.setFeatureState({ source: sourceId, id: hoveredStateId }, { hover: false });
        }
        hoveredStateId = null;
        onLeave();
    });
};

/* Activate map popup on chart click */
export const handleChartClick = (map, layerId, coordinates, properties) => {
    try {
        if (layerId !== 'hs-layer' && layerId !== 'flow-layer' && coordinates.length > 1) {
            coordinates = coordinates[0];
        }

        if (layerId === 'graip-layer' || layerId === 'ntt-layer' || layerId === 'shade-layer') {
            coordinates = calculateCentroid(coordinates);
        }

        new mapboxgl.Popup().setLngLat(coordinates).setHTML(Popup(properties, layerId)).addTo(map);
        map.flyTo({
            center: coordinates,
            zoom: 14,
            essential: true
        });
    } catch (err) {
        console.log(err);
    }
};

/* Activate map popup on table click */
export const activateFeature = (map, layerId, coordinates, properties) => {
    if (
        layerId === 'graip-layer' ||
        layerId === 'ntt-layer' ||
        layerId === 'shade-layer' ||
        layerId === 'hs-flow-layer'
    ) {
        coordinates = calculateCentroid(coordinates[0]);
    }

    new mapboxgl.Popup().setLngLat(coordinates).setHTML(Popup(properties, layerId)).addTo(map);
    map.flyTo({
        center: coordinates,
        zoom: 14,
        essential: true
    });
};

/* Calculate cooridnates centroid */
export const calculateCentroid = (coordinates) => {
    if (coordinates.length === 1) {
        // ntt data edge case
        coordinates = coordinates[0];
    }
    let n = 0,
        xSum = 0,
        ySum = 0;
    coordinates.forEach((point) => {
        xSum += point[0];
        ySum += point[1];
        n += 1;
    });
    return [xSum / n, ySum / n];
};

/* Create html for map popup */

const nttPopup = [
    { label: 'Project Rank', dataKey: 'ntt_sorted_id' },
    { label: '2018 Crop', dataKey: 'croptype' },
    { label: '2018 Irrigation Type', dataKey: 'irrtype' },
    { label: 'Acres', dataKey: 'acres', decimals: 2 },
    { label: 'Recommended BMP', dataKey: 'scenario_text' },
    { label: 'TP Uplift (lbs/yr)', dataKey: 'delta_total_p_lbs_yr', decimals: 2 },
    { label: 'TN Uplift (lbs/yr)', dataKey: 'delta_total_n_lbs_yr', decimals: 2 },
    { label: 'Sediment Uplift (tons/yr)', dataKey: 'delta_sed_tons_yr', decimals: 2 },
    { label: 'Project cost ($)', dataKey: 'net_present_cost', decimals: 0 },
    { label: 'Efficiency (tons/yr/$)', dataKey: 'efficiency', decimals: 4 }
];

const graipPopup = [
    { label: 'Project Rank', dataKey: 'graip_sorted_id' },
    { label: 'Scenario', dataKey: 'scenario' },
    { label: 'Traffic', dataKey: 'traffic' },
    { label: 'Surface Type', dataKey: 'surface' },
    { label: 'Maintenance Type', dataKey: 'maint_level' },
    { label: 'Length (miles)', dataKey: 'miles', decimals: 2 },
    { label: 'Sediment uplift (tons/yr)', dataKey: 'delta_sed_deli', decimals: 2 },
    { label: 'Project cost ($)', dataKey: 'cost', decimals: 0 },
    { label: 'Efficiency (tons/yr/$)', dataKey: 'efficiency', decimals: 2 }
];

const hsPopup = [
    { label: 'Project Rank', dataKey: 'hs_veg_sorted_id' },
    { label: 'Stream', dataKey: 'stream' },
    { label: 'Reach (river km)', dataKey: 'reach_kms' },
    { label: 'Project Type', dataKey: 'proj_type' },
    { label: 'Temperature Impact (degree*hr)', dataKey: 'deg_hr_impact', decimals: 2 },
    { label: 'Project cost ($)', dataKey: 'cost', decimals: 0 },
    { label: 'Efficiency (degree*hr/$)', dataKey: 'efficiency', decimals: 2 }
];

const shadePopup = [
    { label: 'Project Rank', dataKey: 'sorted_id' },
    { label: 'Stream', dataKey: 'stream_name' },
    { label: 'Owner', dataKey: 'owner' },
    { label: 'Parcel#', dataKey: 'parcel_num' },
    { label: 'Reach (river km)', dataKey: 'stream_km', decimals: 2 },
    { label: 'Project Type', dataKey: 'proj_type' },
    { label: 'Blocked solar load (kcals)', dataKey: 'uplift', decimals: 2 },
    { label: 'Planted acres', dataKey: 'planting_a', decimals: 2 },
    { label: 'Project cost ($)', dataKey: 'cost', decimals: 0 },
    { label: 'Efficiency (kcal/$)', dataKey: 'efficiency', decimals: 3 }
];

const culvertPopup = [
    { label: 'Fish Passage Status (ODFW)', dataKey: 'fpbfpassta' },
    { label: 'Stream Name', dataKey: 'fpbstrnm' },
    { label: 'Ownership', dataKey: 'fpbown' },
    { label: 'Upstream Avg Drainage Area (sq km)', dataKey: 'updrain', decimals: 2 },
    { label: 'Upstream Avg % Slope', dataKey: 'upslope', decimals: 2 },
    { label: 'Upstream Avg Stream Power Index', dataKey: 'upspi', decimals: 2 },
    { label: 'Upstream Ave Flow Velocity', dataKey: 'upflowvel', decimals: 2 },
    { label: ' Downstream Coho Intrinsic Potential', dataKey: 'cohoip', decimals: 2 },
    { label: 'Downstream Steelhead Intrinsic Potential', dataKey: 'steelip', decimals: 2 },
    { label: 'Downstream Chinook Intrinsic Potential', dataKey: 'chinookip', decimals: 2 },
    { label: 'Anadromous Fish Habitat', dataKey: 'anadromy' },
    { label: 'Watershed', dataKey: 'watershed' },
    { label: 'Subwatershed', dataKey: 'subwatershed' }
];

const taxlotPopup = [
    { label: 'Parcel #', dataKey: 'parcel_num' },
    { label: 'Owner', dataKey: 'owner' },
    { label: 'Area (acres)', dataKey: 'shape_area' },
    { label: 'Watershed', dataKey: 'watershed' },
    { label: 'Subwatershed ', dataKey: 'subwatershed' }
];


const hsFlowPopup = [
    { label: 'Project Rank', dataKey: 'flow_grouped_sorted_id' },
    { label: 'Temp. Impact (deg*hrs)', dataKey: 'degree_hour_decrease' },
    { label: 'River (km)', dataKey: 'river_km' },
    { label: 'Scenario Name', dataKey: 'scenario' },
    { label: 'Watershed', dataKey: 'watershed' },
    { label: 'Subwatershed', dataKey: 'subwatershed' }
];

const flowPopup = [
    { label: 'Project Rank', dataKey: 'flow_sorted_id' },
    { label: 'Certificate Number', dataKey: 'water_right_id' },
    { label: 'Priority Date', dataKey: 'priority_date' },
    { label: 'Stream', dataKey: 'stream' },
    { label: 'CFS', dataKey: 'cfs' },
    { label: 'Acres', dataKey: 'acres', decimals: 2 },
    { label: 'Duty', dataKey: 'duty' },
    { label: 'Reach (river km)', dataKey: 'river_km' },
    { label: 'Temperature Impact (degree*hr)', dataKey: 'degree_hour_decrease', decimals: 2 },
    { label: 'Project cost ($)', dataKey: 'total_cost', decimals: 0 },
    { label: 'Efficiency (degree*hr/$)', dataKey: 'efficiency', decimals: 2 },
    { label: 'NDVI Mean', dataKey: 'ndvi_mean', decimals: 3 },
    { label: 'Scenario Name', dataKey: 'scenario' },
    { label: 'Watershed', dataKey: 'watershed' },
    { label: 'Subwatershed', dataKey: 'subwatershed' }
];

const layerPopup = {
    'ntt-layer': nttPopup,
    'graip-layer': graipPopup,
    'heat-layer': hsPopup,
    'hs-layer': hsPopup,
    'shade-layer': shadePopup,
    'culvert-layer': culvertPopup,
    'taxlot-layer': taxlotPopup,
    'hs-flow-layer': hsFlowPopup,
    'flow-layer': flowPopup
};

const skipRules = [null, undefined, Number.NEGATIVE_INFINITY, 'null', 'NaN', NaN];

export const Popup = (properties, layerId) => {
    if (layerId in layerPopup) {
        let html = '<span>';
        layerPopup[layerId].forEach((elem) => {
            const value =
                elem.decimals >= 0
                    ? roundString(properties[elem.dataKey], elem.decimals)
                    : properties[elem.dataKey];

            if (!skipRules.includes(value)) {
                switch (elem.label) {
                    case 'Project cost ($)':
                        html += `<strong> ${elem.label} </strong> : ${formatPrice(value)}</br>`;
                        break;
                    case 'Priority Date':
                        html += `<strong> ${elem.label} </strong> : ${formatDate(value)} </br>`;
                        break;
                    default:
                        html += `<strong> ${elem.label} </strong> : ${value} </br>`;
                }
            }
        });
        html += '</span>';
        return html;
    }
};
