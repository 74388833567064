/*
 * Number formatting auxilary functions.
 */
export const roundString = (value, decimals) => {
    switch (decimals) {
        case 4:
            return Math.round((parseFloat(value) + Number.EPSILON) * 10000) / 10000;
        case 3:
            return Math.round((parseFloat(value) + Number.EPSILON) * 1000) / 1000;
        case 2:
            return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100;
        case 1:
            return Math.round((parseFloat(value) + Number.EPSILON) * 10) / 10;
        default:
            return Math.round(parseFloat(value));
    }
};

export const formatPrice = (value) => {
    return new Intl.NumberFormat('en').format(Math.round(parseFloat(value)));
};

export const formatNumber = (value, decimals) => {
    return new Intl.NumberFormat('en').format(roundString(value, decimals));
};

export const formatDate = (date) => {
    return date.substring(0, 10);
};
