import { useEffect, useMemo, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import AlertProvider from '../Root/contexts/AlertProvider';
import { useTokenStore } from './useTokenStore';

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => qs.parse(search.slice(1)), [search]);
}

export const useSaveTokensFromQuery = () => {
    const params = useQuery();
    const history = useHistory();
    const alert = useContext(AlertProvider.context);

    useEffect(() => {
        if (
            typeof params.accessToken === 'string' &&
            typeof params.refreshToken === 'string' &&
            params.accessToken &&
            params.refreshToken
        ) {
            useTokenStore.getState().setTokens({
                accessToken: params.accessToken,
                refreshToken: params.refreshToken
            });
            setTimeout(() => {
                history.push('/');
                alert.setAlert({ status: 'authenticated' });
            }, 100);
        }

        if (typeof params.status === 'string' && params.status === 'ERR_USER_NOT_IN_SCOPE') {
            history.push('/');
            alert.setAlert({ status: 'blocked' });
        }
    }, [params]);
};
