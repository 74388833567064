import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ProgramTable from '../Table/ProgramTable';
import { useApi } from '../../Api';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        backgroundColor: '#fff'
    }
}));

const Programs = (props) => {
    const api = useApi();
    const classes = useStyles();
    const { user, map, setNavBarSync } = props;

    const [data, setData] = useState([]);
    const [mode, setMode] = useState('personal');
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        const response = mode === 'personal' ? api.fetchUserPrograms() : api.fetchPrograms();
        response.then((result) => {
            if (result !== undefined) {
                setData(result.data.data);
            }
        });

        if (update) {
            setUpdate(false);
        }
    }, [mode, update]);

    return (
        <div className={classes.root}>
            <ProgramTable
                data={data}
                map={map}
                mode={mode}
                setMode={setMode}
                setUpdate={setUpdate}
                setNavBarSync={setNavBarSync}
            />
        </div>
    );
};

export default Programs;
