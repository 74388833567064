import React from 'react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import AllInboxIcon from '@material-ui/icons/AllInbox';

const TableLoading = (props) => {
    if (!props.loading && !props.empty) {
        return null;
    }

    return (
        <div
            style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '50px',
                width: '100%',
                height: '100%',
                zIndex: 3
            }}
        >
            {props.loading && <LinearProgress />}

            {props.empty && (
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                    <AllInboxIcon style={{ fontSize: '5rem', color: '#aaa8afb0' }} />
                    <Typography style={{ fontSize: '1.25rem', color: '#aaa8afb0' }}>
                        Empty data
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default TableLoading;
