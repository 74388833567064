import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useApi } from '../../../Api';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: '600px',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    dialogTitle: {
        background: '#ecf1ff'
    },
    textField: {
        margin: theme.spacing(1),
        width: '100%'
    },
    progress: {
        position: 'absolute',
        textAlign: 'center',
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)',
        marginRight: '-50%'
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography style={{ fontSize: '1.25rem' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

const TableModal = (props) => {
    const api = useApi();
    const classes = useStyles();
    const timer = useRef();

    const [programOptions, setOptions] = useState([]);
    const [newProgram, setNewProgram] = useState('');
    const [preProgram, setPreProgram] = useState('');
    const [loading, setLoading] = useState(false);
    const [reload, toogleReload] = useState(false);

    const { selected, setSelected, type, onAdd } = props;
    const { open, setOpen } = props;

    useEffect(() => {
        api.fetchUserPrograms().then((value) => {
            setOptions(value.data.data);
        });
    }, [reload]);

    // controls logic for (Add and Create) inputs
    const handleChange = (event, setInput, clearInput) => {
        setInput(event.target.value);
        clearInput('');
    };

    const handleClose = () => {
        setOpen(false);
        toogleReload(reload ? false : true);
        setNewProgram('');
    };

    const handleSubmit = () => {
        setLoading(true);
        if (newProgram !== '') {
            api.createProgram(newProgram, [...selected], type).then((value) => {
                timer.current = setTimeout(() => {
                    setLoading(false);
                    setSelected(new Set());
                    handleClose();
                    onAdd();
                }, 1000);
            });
            return;
        }
        if (preProgram !== '') {
            api.addProject(preProgram, [...selected], type).then((value) => {
                timer.current = setTimeout(() => {
                    setLoading(false);
                    setSelected(new Set());
                    handleClose();
                    onAdd();
                }, 1000);
            });
            return;
        }
    };

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
                <DialogTitle
                    id='customized-dialog-title'
                    classesName={classes.dialogTitle}
                    onClose={handleClose}
                >
                    Add Project
                </DialogTitle>
                <DialogContent dividers>
                    {loading && <CircularProgress className={classes.progress} />}

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <p id='add-to-pre-existing'>Add to Pre-existing program</p>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId='demo-simple-select-helper-label'
                                    id='demo-simple-select-helper'
                                    value={preProgram}
                                    displayEmpty
                                    onChange={(event) =>
                                        handleChange(event, setPreProgram, setNewProgram)
                                    }
                                >
                                    {programOptions.map((value) => (
                                        <MenuItem value={value.program_name}>
                                            {value.program_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            or
                        </Grid>
                        <Grid item xs={6}>
                            <p id='create-new-program'>Create new program</p>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className={classes.textField}
                                id='outlined-basic'
                                label='Enter program name'
                                value={newProgram}
                                onChange={(event) =>
                                    handleChange(event, setNewProgram, setPreProgram)
                                }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleSubmit} color='primary'>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TableModal;
