import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SyncIcon from '@material-ui/icons/Sync';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 260
    },
    form: {
        padding: '0 8px',
        overflow: 'auto',
        flexWrap: 'unset',
        maxHeight: 400
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '8px',
        borderBottom: '1px solid #bfb6b6',
        color: 'rgba(0, 0, 0, 0.64)'
    },
    grow: {
        flexGrow: 1
    },
    closeButton: {
        padding: '8px',
        left: 0,
        top: 0
    },
    resetButton: {
        padding: '8px',
        right: 0,
        top: 0
    }
}));

export function Filter(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const { rows, setRows, options, setOptions, column } = props;
    const [state, setstate] = useState({});

    useEffect(() => {
        if (!(column in options) && rows.length !== 0) {
            const temp = {};
            rows.forEach((row) => {
                temp[row[column]] = true;
            });
            setOptions((options) => {
                return { ...options, [column]: temp };
            });
            setstate(temp);
        }

        setstate(options[column]);
    }, []);

    const handleChange = (event) => {
        setstate({ ...state, [event.target.name]: event.target.checked });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        if (JSON.stringify(state) !== JSON.stringify(options[column])) {
            setOptions({ ...options, [column]: state });

            // apply all filters from other columns
            let newRows = [...rows];
            Object.keys(options).forEach((key) => {
                if (key !== column) {
                    newRows = newRows.filter((row) => {
                        return options[key][row[key]];
                    });
                }
            });

            // set rows and apply filter of the current column
            setRows(
                [...newRows].filter((row) => {
                    return state[row[column]];
                })
            );
        }
        setAnchorEl(null);
    };

    const handleReset = () => {
        // reset all filter keys to true
        const temp = {};
        Object.keys(state).map((key) => {
            temp[key] = true;
        });

        setstate(temp);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{ position: 'absolute', right: 0 }}>
            <IconButton aria-describedby={id} onClick={handleClick} aria-label='sort'>
                <MoreVertIcon style={{ fontSize: '1.25rem' }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <FormControl component='fieldset' className={classes.root}>
                    <div className={classes.header}>
                        <Typography
                            variant='subtitle1'
                            style={{ fontSize: '0.95rem', fontWeight: 500 }}
                        >
                            FilterBy
                        </Typography>
                        <div className={classes.grow} />

                        <Tooltip title='Reset'>
                            <IconButton
                                aria-label='reset'
                                className={classes.resetButton}
                                onClick={handleReset}
                            >
                                <SyncIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title='Close'>
                            <IconButton
                                aria-label='close'
                                className={classes.closeButton}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <FormGroup className={classes.form}>
                        {state &&
                            Object.keys(state).map((key) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state[key]}
                                            style={{ color: 'rgb(87, 106, 207)' }}
                                            onChange={handleChange}
                                            name={key}
                                        />
                                    }
                                    key={key}
                                    label={key}
                                />
                            ))}
                    </FormGroup>
                </FormControl>
            </Popover>
        </div>
    );
}
