import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import TableFilter from './TableFilter/TableFilter';
import { CSVLink } from 'react-csv';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '84px',
        padding: '0 24px 0 24px',
        color: 'rgba(0, 0, 0, 0.67)'
    },
    options: {
        display: 'flex',
        marginLeft: 'auto'
    },
    infoButton: {
        fontWeight: 400,
        textTransform: 'none',
        fontSize: '0.95rem'
    },
    addButton: {
        fontWeight: 400,
        marginLeft: '16px',
        textTransform: 'none'
    }
});

const TableHeader = (props) => {
    const classes = useStyles();
    const { title, info, map, data, rows, setRows, selected, handleAdd } = props;

    return (
        <div className={classes.root}>
            <Typography
                style={{ fontSize: '1.1rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.87)' }}
            >
                {title}
            </Typography>

            <div className={classes.options}>
                {selected.size !== 0 && (
                    <div className={classes.options}>
                        {info.map((elem) => (
                            <Button className={classes.infoButton}>
                                <span style={{ fontWeight: 500 }}>{elem.name}: </span>
                                {elem.name === 'Cost' ? '$' : ''}
                                {elem.value}
                            </Button>
                        ))}

                        <Button
                            color='primary'
                            className={classes.addButton}
                            endIcon={<ShoppingCartIcon />}
                            onClick={handleAdd}
                        >
                            {selected.size} Project(s) Selected
                        </Button>
                    </div>
                )}

                <TableFilter map={map} data={data} setRows={setRows} />
                <Tooltip title='Export'>
                    <CSVLink data={rows}>
                        <IconButton aria-label='Export'>
                            <SaveAltIcon />
                        </IconButton>
                    </CSVLink>
                </Tooltip>
            </div>
        </div>
    );
};

export default TableHeader;
