import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import DataGrid from 'react-data-grid';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useWindowDimensions from '../Dimensions/WindowDimensions';
import TableHeader from './TableHeader';
import TableLoading from './Loading';
import { activateFeature } from '../lib/maplib';
import { sortBy } from 'underscore';
import { formatNumber, roundString, formatPrice } from '../lib/formatlib';
import styles from './style.css';

const useStyles = makeStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        zIndex: 3
    },
    headerCell: {
        color: '#3c3b3f',
        fontSize: ' 0.875rem',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        '&:after': {
            content: "''",
            position: 'absolute',
            borderLeft: '2px solid rgb(220, 227, 233)',
            top: '35%',
            left: '0',
            height: '40%',
            marginTop: 'auto',
            marginBottom: 'auto'
        }
    },
    grid: {
        height: '100%',
        overflowY: 'hidden',
        position: 'relative'
    }
});

const createData = (data, setRange) => {
    const filterData = [];

    data.features.forEach((value) => {
        value.properties['coordinates'] = value.geometry.coordinates;
        value.properties['flow_grouped_sorted_id'] = parseInt(
            value.properties['flow_grouped_sorted_id']
        );
        value.properties['river_km'] = roundString(value.properties['river_km'], 2);
        value.properties['degree_hour_decrease'] = roundString(
            value.properties['degree_hour_decrease'],
            1
        );
        filterData.push(value.properties);
    });

    return filterData;
};

const HsFlowTable = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { height, width } = useWindowDimensions();
    const { user, map, data, setNavBarSync } = props;

    const [rows, setRows] = useState([]);

    const [sortColumn, setSortColumn] = useState('efficiency');
    const [sortDirection, setSortDirection] = useState('DESC');

    useEffect(() => {
        if (data !== null) {
            const cleanData = createData(data);
            setRows(cleanData);
        }
    }, [data]);

    const ActionCell = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    color: '#3c3b3f',
                    fontSize: ' 0.875rem',
                    fontWeight: 500
                }}
            >
                <Tooltip title='Locate'>
                    <IconButton style={{ padding: 0, margin: 'auto' }} aria-label='sort'>
                        <PinDropIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const HeaderCell = ({ column }) => {
        return (
            <div className={classes.headerCell}>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={column.sortable ? () => sort(column.key) : () => {}}
                >
                    {column.name}
                </span>

                {sortColumn === column.key && sortDirection === 'ASC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowUpwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                {sortColumn === column.key && sortDirection === 'DESC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowDownwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}
            </div>
        );
    };

    const columns = [
        {
            width: 120,
            name: 'Locate',
            key: 'locate',
            formatter: (data) => LocateFormatter(data),
            headerRenderer: () => ActionCell()
        },

        {
            width: 100,
            name: 'Rank',
            key: 'flow_grouped_sorted_id',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 150,
            name: 'River (km)',
            key: 'river_km',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 240,
            name: 'Temp. Impact (deg*hrs)',
            key: 'degree_hour_decrease',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 260,
            name: 'Scenario',
            key: 'scenario',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 260,
            name: 'Watershed',
            key: 'watershed',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 320,
            name: 'Subwatershed',
            key: 'subwatershed',
            sortable: true,
            headerRenderer: HeaderCell
        }
    ];

    const LocateFormatter = (data) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Tooltip title='Locate'>
                    <IconButton
                        onClick={() => handleLocateClick(data)}
                        style={{ padding: 0, margin: 'auto' }}
                        aria-label='sort'
                    >
                        <PinDropIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const handleLocateClick = (data) => {
        activateFeature(map, 'hs-flow-layer', data.row.coordinates, data.row);
        history.push('/map');
        setNavBarSync(true);
    };

    const sort = (value) => {
        setRows(sortDirection === 'ASC' ? sortBy(rows, value).reverse() : sortBy(rows, value));
        setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        setSortColumn(value);
    };

    return (
        <div className={classes.root}>
            <TableHeader
                map={map}
                title={'Stream Temperature (Grouped Water Rights)'}
                data={data}
                rows={rows}
                setRows={setRows}
                selected={new Set()}
                info={[]}
            />

            <div className={classes.grid}>
                <TableLoading loading={data === null} empty={rows.length === 0} />
                <DataGrid
                    rowKey='id'
                    columns={columns}
                    rows={rows}
                    headerRowHeight={50}
                    rowHeight={38}
                />
            </div>
        </div>
    );
};

export default HsFlowTable;
