import React, { useState, useEffect } from 'react';
import CanvasJSReact from '../../../assets/canvasjs.react';
import Typography from '@material-ui/core/Typography';
import { sortBy } from 'underscore';
import { handleChartClick } from '../../lib/maplib';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Chart = (props) => {
    const [data, setData] = useState({});
    const [labels, setLabels] = useState({});
    const [selected, setSelected] = useState(-1);

    const { map, chartId, syncChart, setSyncChart } = props;

    useEffect(() => {
        const formattedData = [];
        let xAxisLabel = '',
            yAxisLabel = '',
            chartLabel = '';

        if (map !== null) {
            switch (chartId) {
                case 'hs':
                    const sortedData = sortBy(
                        map.queryRenderedFeatures({ layers: ['hs-layer'] }),
                        (elem) => {
                            return elem.properties.id;
                        }
                    );
                    let currentId = -1;
                    sortedData.forEach((feature) => {
                        if (feature.properties.id !== currentId) {
                            formattedData.push({
                                x: parseFloat(feature.properties.cost),
                                y: parseFloat(feature.properties.deg_hr_impact),
                                z: feature.properties.id,
                                markerSize: feature.properties.id === selected ? 20 : 0,
                                markerType: feature.properties.id === selected ? 'cross' : '',
                                coordinates: [
                                    feature.geometry.coordinates[0],
                                    feature.geometry.coordinates[1]
                                ],
                                properties: feature.properties,
                                click: (e) => {
                                    handleChartClick(
                                        map,
                                        'hs-layer',
                                        e.dataPoint.coordinates,
                                        e.dataPoint.properties
                                    );
                                    setSelected(e.dataPoint.z);
                                }
                            });
                            currentId = feature.properties.id;
                        }
                    });

                    chartLabel = 'Stream Temperature (Planting)';
                    xAxisLabel = 'Cost';
                    yAxisLabel = 'Uplift';
                    break;

                case 'hsflow':
                    map.queryRenderedFeatures({ layers: ['hs-flow-layer'] }).forEach((feature) => {
                        formattedData.push({
                            x: parseFloat(feature.properties.id),
                            y: parseFloat(feature.properties.watershed_id),
                            z: feature.properties.id,
                            markerSize: feature.properties.id === selected ? 20 : 0,
                            markerType: feature.properties.id === selected ? 'cross' : '',
                            coordinates: feature.geometry.coordinates,
                            properties: feature.properties,
                            click: (e) => {
                                handleChartClick(
                                    map,
                                    'hs-flow-layer',
                                    e.dataPoint.coordinates,
                                    e.dataPoint.properties
                                );
                                setSelected(e.dataPoint.z);
                            }
                        });
                    });

                    chartLabel = 'Stream Temperature (Flow)';
                    xAxisLabel = 'Project ID';
                    yAxisLabel = 'Watershed ID';
                    break;

                case 'shade':
                    map.queryRenderedFeatures({ layers: ['shade-layer'] }).forEach((feature) => {
                        formattedData.push({
                            x: parseFloat(feature.properties.cost),
                            y: parseFloat(feature.properties.uplift),
                            z: feature.properties.sorted_id,
                            markerSize: feature.properties.sorted_id === selected ? 20 : 0,
                            markerType: feature.properties.sorted_id === selected ? 'cross' : '',
                            coordinates: feature.geometry.coordinates,
                            properties: feature.properties,
                            click: (e) => {
                                handleChartClick(
                                    map,
                                    'shade-layer',
                                    e.dataPoint.coordinates,
                                    e.dataPoint.properties
                                );
                                setSelected(e.dataPoint.z);
                            }
                        });
                    });

                    chartLabel = 'Solar Load';
                    xAxisLabel = 'Cost';
                    yAxisLabel = 'Uplift (kcals)';
                    break;

                case 'ntt':
                    map.queryRenderedFeatures({ layers: ['ntt-layer'] }).forEach((feature) => {
                        formattedData.push({
                            x: Math.abs(parseFloat(feature.properties.net_present_cost)),
                            y: parseFloat(feature.properties.delta_sed_tons_yr),
                            z: feature.properties.id,
                            markerSize: feature.properties.id === selected ? 20 : 0,
                            markerType: feature.properties.id === selected ? 'cross' : '',
                            coordinates: feature.geometry.coordinates,
                            properties: feature.properties,
                            click: (e) => {
                                handleChartClick(
                                    map,
                                    'ntt-layer',
                                    e.dataPoint.coordinates,
                                    e.dataPoint.properties
                                );
                                setSelected(e.dataPoint.z);
                            }
                        });
                    });

                    chartLabel = 'Top Field Sediment';
                    xAxisLabel = 'Cost';
                    yAxisLabel = 'Delta Sediment (tons/yr)';
                    break;

                case 'graip':
                    map.queryRenderedFeatures({ layers: ['graip-layer'] }).forEach((feature) => {
                        formattedData.push({
                            x: parseFloat(feature.properties.cost),
                            y: parseFloat(feature.properties.delta_sed_prod),
                            z: feature.properties.id,
                            markerSize: feature.properties.id === selected ? 20 : 0,
                            markerType: feature.properties.id === selected ? 'cross' : '',
                            coordinates: feature.geometry.coordinates,
                            properties: feature.properties,
                            click: (e) => {
                                handleChartClick(
                                    map,
                                    'graip-layer',
                                    e.dataPoint.coordinates,
                                    e.dataPoint.properties
                                );
                                setSelected(e.dataPoint.z);
                            }
                        });
                    });

                    chartLabel = 'Top Road';
                    xAxisLabel = 'Cost';
                    yAxisLabel = 'Sediment Production Uplift (lbs/acre/yr)';
                    break;

                case 'flow':
                    map.queryRenderedFeatures({ layers: ['flow-layer'] }).forEach((feature) => {
                        formattedData.push({
                            x: parseFloat(feature.properties.total_cost),
                            y: parseFloat(feature.properties.degree_hour_decrease),
                            z: feature.properties.id,
                            markerSize: feature.properties.id === selected ? 20 : 0,
                            markerType: feature.properties.id === selected ? 'cross' : '',
                            coordinates: feature.geometry.coordinates,
                            properties: feature.properties,
                            click: (e) => {
                                handleChartClick(
                                    map,
                                    'flow-layer',
                                    e.dataPoint.coordinates,
                                    e.dataPoint.properties
                                );
                                setSelected(e.dataPoint.z);
                            }
                        });
                    });

                    chartLabel = 'Stream Temperature (Water Rights)';
                    xAxisLabel = 'Cost';
                    yAxisLabel = 'Uplift';
                    break;
            }

            setData(formattedData);
            setLabels({ chartLabel, xAxisLabel, yAxisLabel });
        }

        if (syncChart) {
            console.log(syncChart);
            setSyncChart(false);
        }
    }, [syncChart, selected, chartId]);

    if (Object.keys(data).length === 0) {
        return (
            <Typography style={{ fontSize: '1.3rem' }} gutterBottom>
                {labels.chartLabel} projects don't exist in this area.
            </Typography>
        );
    }

    const options = {
        zoomEnabled: true,
        animationEnabled: true,
        title: {
            text: `${labels.chartLabel} Projects`,
            fontFamily: 'roboto',
            fontWeight: 400,
            fontSize: 18,
            fontColor: '#575961'
        },
        axisX: {
            title: labels.xAxisLabel,
            titleFontColor: '#575961',
            fontFamily: 'roboto',
            fontSize: 16
            //maximum: data[data.length - 1].x, // this fixes error when data is too cluster together
        },
        axisY: {
            includeZero: false,
            title: labels.yAxisLabel,
            titleFontColor: '#575961',
            fontFamily: 'roboto',
            fontSize: 16
        },
        data: [{ type: 'scatter', dataPoints: data }]
    };

    return <CanvasJSChart options={options} />;
};

export default Chart;
