import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import MapIcon from '@material-ui/icons/Map';
import Fab from '@material-ui/core/Fab';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { addSource, addLayer, addHover, addClick } from '../../lib/maplib';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        position: 'absolute',
        zIndex: '2',
        left: '2%',
        bottom: '14%',
        color: 'rgba(0, 0, 0, 0.76)'
    },
    fab: {
        color: 'rgba(0, 0, 0, 0.76)'
    },
    title: {
        display: 'flex',
        background: 'rgb(255, 255, 255)',
        color: '#1e262c',
        alignItems: 'center',
        padding: '8px 4px 8px 8px',
        borderRadius: '4px 4px 0px 0px',
        borderBottom: '2px solid #ebeaf0'
    },
    closeButton: {
        color: '#242527',
        padding: 0
    },
    filterContainer: {
        padding: '4px 16px',
        width: '300px'
    }
}));

const MapType = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const {
        map,
        setSyncFilter,
        hsData,
        hsFlowData,
        flowData,
        shadeData,
        nttData,
        graipData,
        culvertData,
        taxlotData,
        watershedData,
        subwaterData
    } = props;

    const [mapType, setMapType] = useState('streets-v11');

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // open popover
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // close popover
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeMap = (event) => {
        map.setStyle('mapbox://styles/mapbox/' + event.target.value);
        setTimeout(() => {
            addSource(map, 'hs-data', 'geojson', hsData);
            addLayer(map, 'hs-layer', 'hs-data', 'circle', {
                'circle-color': [
                    'interpolate',
                    ['linear'],
                    ['to-number', ['get', 'hs_veg_sorted_id']],
                    0,
                    '#349beb',
                    40,
                    '#2086e6',
                    100,
                    '#026fd6',
                    160,
                    '#002b80'
                ],
                'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.8],
                'circle-radius': ['step', ['zoom'], 7, 10, 10, 12, 12]
            });
            map.addLayer({
                id: 'hs-layer-rank',
                type: 'symbol',
                source: 'hs-data',
                layout: {
                    'text-field': '{hs_veg_sorted_id}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 14
                },
                minzoom: 12,
                paint: {
                    'text-color': '#fff'
                }
            });
            addClick(map, 'hs-layer');
            addHover(map, 'hs-layer', 'hs-data');

            addSource(map, 'shade-data', 'geojson', shadeData);
            addLayer(map, 'shade-layer', 'shade-data', 'fill', {
                'fill-color': [
                    'interpolate',
                    ['linear'],
                    ['to-number', ['get', 'sorted_id']],
                    0,
                    'red',
                    450,
                    'yellow',
                    890,
                    'green'
                ]
            });
            map.addLayer({
                id: 'shade-layer-rank',
                type: 'symbol',
                source: 'shade-data',
                layout: {
                    'text-field': '{sorted_id}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 14
                },
                minzoom: 12
            });
            addClick(map, 'shade-layer');
            addHover(map, 'shade-layer', 'shade-data');

            addSource(map, 'ntt-data', 'geojson', nttData);
            addLayer(map, 'ntt-layer', 'ntt-data', 'fill', {
                'fill-color': [
                    'interpolate',
                    ['linear'],
                    ['to-number', ['get', 'ntt_sorted_id']],
                    0,
                    '#7f2704',
                    200,
                    '#a63603',
                    600,
                    '#d94801',
                    1200,
                    '#f16913',
                    2000,
                    '#fd8d3c',
                    3000,
                    '#fdae6b',
                    4000,
                    '#fdd0a2'
                ],
                'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.6]
            });
            map.addLayer({
                id: 'ntt-layer-rank2',
                type: 'symbol',
                source: 'ntt-data',
                layout: {
                    'text-field': '{ntt_sorted_id}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 14
                },
                minzoom: 12,
                paint: {
                    'text-color': '#fff'
                },
                filter: ['!=', ['get', 'scenario_text'], 'Current conditions']
            });
            addClick(map, 'ntt-layer');
            addHover(map, 'ntt-layer', 'ntt-data');

            addSource(map, 'graip-data', 'geojson', graipData);
            addLayer(map, 'graip-layer', 'graip-data', 'line', {
                'line-color': [
                    'interpolate',
                    ['linear'],
                    ['to-number', ['get', 'graip_sorted_id']],
                    0,
                    '#2a1503',
                    1600,
                    '#3c1e08',
                    3200,
                    '#7b481c',
                    5400,
                    '#9a7b4d'
                ],
                'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 1],
                'line-width': 3
            });
            addClick(map, 'graip-layer');
            addHover(map, 'graip-layer', 'graip-data');

            addSource(map, 'culvert-data', 'geojson', culvertData);
            addLayer(map, 'culvert-layer', 'culvert-data', 'circle', {
                'circle-color': 'red',
                'circle-radius': [
                    'interpolate',
                    ['linear'],
                    ['to-number', ['get', 'upspi']],
                    0,
                    2,
                    3000,
                    4,
                    5800,
                    6,
                    8900,
                    8
                ],
                'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.99]
            });
            addClick(map, 'culvert-layer');
            addHover(map, 'culvert-layer', 'culvert-data');

            addSource(map, 'taxlot-data', 'geojson', taxlotData);
            addSource(map, 'taxlot-data', 'geojson', taxlotData);
            addLayer(map, 'taxlot-layer', 'taxlot-data', 'line', {
                'line-color': '#c62a0c',
                'line-width': 1
            });

            addSource(map, 'watershed-data', 'geojson', watershedData);
            addLayer(map, 'watershed-layer', 'watershed-data', 'fill', {
                'fill-color': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    'rgba(197, 195, 195, 0.15)',
                    'rgba(0,0,0,0)'
                ],

                'fill-opacity': ['step', ['zoom'], 0.6, 10, 0],
                'fill-outline-color': '#991e02'
            });
            addHover(map, 'watershed-layer', 'watershed-data');

            addSource(map, 'subwater-data', 'geojson', subwaterData);
            addLayer(map, 'subwater-layer', 'subwater-data', 'fill', {
                'fill-color': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    'rgba(197, 195, 195, 0.15)',
                    'rgba(0,0,0,0)'
                ],
                'fill-opacity': ['step', ['zoom'], 0.6, 10, 0],
                'fill-outline-color': '#991e02'
            });
            addHover(map, 'subwater-layer', 'subwater-data');

            addSource(map, 'hs-flow-data', 'geojson', hsFlowData);
            addLayer(map, 'hs-flow-layer', 'hs-flow-data', 'line', {
                'line-color': 'green',
                'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.6],
                'line-width': 3
            });
            addClick(map, 'hs-flow-layer');
            addHover(map, 'hs-flow-layer', 'hs-flow-data')


            addSource(map, 'flow-data', 'geojson', flowData);
            addLayer(map, 'flow-layer', 'flow-data', 'circle', {
                'circle-color': 'purple',
                'circle-radius': 5,
                'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.99]
            });
            addClick(map, 'flow-layer');
            addHover(map, 'flow-layer', 'flow-data');

            setSyncFilter(true);
        }, 1000);
        setMapType(event.target.value);
    };

    return (
        <div className={classes.buttonContainer}>
            <Fab aria-describedby={id} className={classes.fab} onClick={handleClick}>
                <MapIcon />
            </Fab>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <div className={classes.title}>
                    <Typography component='legend'>Map Type</Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Tooltip title='Close'>
                        <IconButton
                            aria-label='close'
                            className={classes.closeButton}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>

                <div className={classes.filterContainer}>
                    <FormGroup>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                aria-label='map types'
                                name='map types'
                                value={mapType}
                                onChange={changeMap}
                            >
                                <FormControlLabel
                                    value='streets-v11'
                                    control={<Radio color='primary' />}
                                    label='Streets-view'
                                />
                                <FormControlLabel
                                    value='satellite-streets-v11'
                                    control={<Radio color='primary' />}
                                    label='Satellite-view'
                                />
                                <FormControlLabel
                                    value='light-v10'
                                    control={<Radio color='primary' />}
                                    label='Light-view'
                                />

                                <FormControlLabel
                                    value='dark-v10'
                                    control={<Radio color='primary' />}
                                    label='Dark-view'
                                />
                            </RadioGroup>
                        </FormControl>
                    </FormGroup>
                </div>
            </Popover>
        </div>
    );
};

export default MapType;
