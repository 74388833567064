import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.12)'
    }
});

const Filled = (props) => {
    const classes = useStyles();
    const { value, min, max, projectRank, totalProjects } = props;

    const color = () => {
        if ((projectRank * 100) / totalProjects < 20) {
            return '#8aff8aa3';
        }

        if ((projectRank * 100) / totalProjects < 40) {
            return '#fff28aa3';
        }

        if ((projectRank * 100) / totalProjects < 60) {
            return '#ffc18aa3';
        }
        return '#ff8a8aa3';
    };

    return (
        <div className={classes.root}>
            <div
                style={{
                    maxWidth: `${(value * 100) / max}%`,
                    backgroundColor: `${color()}`
                }}
            >
                {value}
            </div>
        </div>
    );
};

export default Filled;
