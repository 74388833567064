import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AlertProvider from '../contexts/AlertProvider';

export default function AlertDialog() {
    const alert = useContext(AlertProvider.context);

    const handleClose = () => {
        alert.setAlert({ status: '' });
    };

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{'Blocked Access to resources'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        It appears that your account {alert.state.account} currently does not have
                        access to the following resources. Please contact
                        dave@thefreshwatertrust.org for more information.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary' autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
