import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Popover from '@material-ui/core/Popover';
import { useVerifyLogIn } from '../../auth/useVerifyLogIn';
import { useTokenStore } from '../../auth/useTokenStore';
import AlertProvider from '../contexts/AlertProvider';
import Logout from '../Logout/Logout';
import image from '../../img/slice.png';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1
    },
    appBar: {
        backgroundColor: '#fff',
        borderBottom: '1px solid #d9d7d7',
        display: 'flex',
        flexDirection: 'row'
    },
    link: {
        textDecoration: 'none',
        color: '#fff',
        paddingRight: '1em'
    },
    tab: {
        fontSize: '0.9rem',
        margin: '4px 0',
        fontWeight: 400,
        textTransform: 'unset !important',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    dropDown: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff'
    },
    dropDownTab: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        borderStyle: 'solid',
        borderWidth: '0.5px',
        borderColor: '#d6d4d4',
        fontSize: '0.9em',
        fontWeight: 400,
        textTransform: 'unset !important',
        '&:hover': {
            backgroundColor: '#e5e5e9'
        }
    }
}));

const NavBar = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const alert = useContext(AlertProvider.context);
    const isLogIn = useVerifyLogIn();
    const { setTokens } = useTokenStore((s) => s);

    const [tabId, setTabId] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    const { setResizeMap, navBarSync, setNavBarSync } = props;

    const open = Boolean(anchorEl);
    const id = open ? 'tab-dropdown' : undefined;

    useEffect(() => {
        switch (window.location.pathname) {
            case '/':
                setTabId(0);
                break;
            case '/map':
                setTabId(1);
                break;
            case '/ntt-table':
                setTabId(2);
                break;
            case '/shade-table':
                setTabId(2);
                break;
            case '/graip-table':
                setTabId(2);
                break;
            case '/heatsource-table':
                setTabId(2);
                break;
            case '/heatsource-flow-table':
                setTabId(2);
                break;
            case '/programs':
                setTabId(3);
                break;
            case '/about-this-tool':
                setTabId(4);
                break;
        }

        if (navBarSync) {
            setNavBarSync(false);
            setResizeMap(true);
        }
    }, [navBarSync]);

    const handleAuth = () => {
        if (!isLogIn) {
            history.push('/');
            alert.setAlert({ status: 'login' });
            setTabId(0);
        }
    };

    const handleClick = (tabId, path) => {
        setTabId(tabId);
        history.push(path);
        handleAuth();
    };

    const handleLogout = () => {
        setTokens({ accessToken: '', refreshToken: '' });
        alert.setAlert({ status: 'terminated' });
        setTabId(0);
    };

    return (
        <AppBar className={classes.appBar} elevation={0} position='static'>
            <a href='/' style={{ height: 40, margin: 'auto 16px auto 32px' }}>
                <img src={image} style={{ height: 40 }}></img>
            </a>
            <Tabs
                value={tabId}
                style={{ width: '100%', marginLeft: 16 }}
                indicatorColor='primary'
                textColor='primary'
            >
                <Tab
                    className={classes.tab}
                    label='Home'
                    onClick={() => {
                        handleClick(0, '/');
                    }}
                />
                <Tab
                    label='Map'
                    className={classes.tab}
                    onClick={() => {
                        handleClick(1, '/map');
                        setResizeMap(true);
                    }}
                />
                <Tab
                    label='Table'
                    className={classes.tab}
                    onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                />
                <Tab
                    label='Programs'
                    className={classes.tab}
                    onClick={() => handleClick(3, '/programs')}
                />
                <Tab
                    label='About This Tool'
                    className={classes.tab}
                    onClick={() => handleClick(4, '/about-this-tool')}
                />

                <div style={{ flex: 1 }} />
                {isLogIn ? (
                    <Logout>
                        <Tab label='Logout' className={classes.tab} onClick={handleLogout} />
                    </Logout>
                ) : (
                    <Tab label='Login' className={classes.tab} onClick={handleAuth} />
                )}
            </Tabs>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <div onMouseLeave={() => setAnchorEl(null)} className={classes.dropDown}>
                    <Tab
                        className={classes.dropDownTab}
                        label='Stream Temperature (Riparian)'
                        onClick={() => handleClick(2, '/heatsource-table')}
                    />
                    <Tab
                        className={classes.dropDownTab}
                        label='Stream Temperature (WR)'
                        onClick={() => handleClick(2, '/flow-table')}
                    />
                    <Tab
                        className={classes.dropDownTab}
                        label='Stream Temperature (GWR)'
                        onClick={() => handleClick(2, '/heatsource-flow-table')}
                    />
                    <Tab
                        className={classes.dropDownTab}
                        label='Solar Load'
                        onClick={() => handleClick(2, '/shade-table')}
                    />
                    <Tab
                        className={classes.dropDownTab}
                        label='Field Sediment'
                        onClick={() => handleClick(2, '/ntt-table')}
                    />
                    <Tab
                        className={classes.dropDownTab}
                        label='Road Sediment'
                        onClick={() => handleClick(2, '/graip-table')}
                    />
                </div>
            </Popover>
        </AppBar>
    );
};

export default NavBar;
