import React, { useState, useEffect } from 'react';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import Fab from '@material-ui/core/Fab';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { RangeSlider } from './RangeSlider';
import { withStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        zIndex: '2',
        left: '2%',
        bottom: '4%',
        color: 'rgba(0, 0, 0, 0.76)'
    },
    title: {
        display: 'flex',
        background: 'rgb(255, 255, 255)',
        color: '#1e262c',
        alignItems: 'center',
        padding: '8px 4px 8px 8px',
        borderRadius: '4px 4px 0px 0px',
        borderBottom: '2px solid #ebeaf0'
    },
    popover: {
        background: '#fff',
        borderRadius: '4px',
        zIndex: 1200
    },
    closeButton: {
        color: '#242527',
        padding: 0
    },
    fab: {
        color: 'rgba(0, 0, 0, 0.76)'
    },
    header: {
        display: 'flex',
        padding: '8px 16px 8px 16px',
        fontSize: '0.938em',
        fontWeight: '700',
        color: '#242527',
        width: '300px'
    },
    filter: {
        display: 'flex',
        padding: '0 0 16px 16px',
        width: '300px'
    },
    formGroup: {
        width: '90%',
        color: 'rgba(0, 0, 0, 0.73)'
    },
    formLabel: {
        fontSize: '1rem',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    radioContainer: {
        margin: '0 0 0 -11px'
    },
    hsRadio: {
        margin: '0 0 0px 16px',
        '&$checked': {
            color: '#2086e6'
        }
    },
    hsFlowRadio: {
        margin: '0 0 50px 16px',
        '&$checked': {
            color: 'green'
        }
    },
    shadeRadio: {
        margin: '0 0 50px 16px',
        '&$checked': {
            color: '#ffd300'
        }
    },
    nttRadio: {
        margin: '0 0 50px 16px',
        '&$checked': {
            color: '#fd8d3c'
        }
    },
    graipRadio: {
        margin: '0 0 50px 16px',
        '&$checked': {
            color: 'brown'
        }
    },
    flowRadio: {
        margin: '0 0 54px 16px',
        '&$checked': {
            color: 'purple'
        }
    },
    checked: {}
}));

const HsSwitch = withStyles({
    switchBase: {
        color: '#2086e6',
        '&$checked': {
            color: '#2086e6'
        },
        '&$checked + $track': {
            backgroundColor: '#2086e6'
        }
    },
    checked: {},
    track: {}
})(Switch);

const HsFlowSwitch = withStyles({
    switchBase: {
        color: 'green',
        '&$checked': {
            color: 'green'
        },
        '&$checked + $track': {
            backgroundColor: 'green'
        }
    },
    checked: {},
    track: {}
})(Switch);

const FlowSwitch = withStyles({
    switchBase: {
        color: 'purple',
        '&$checked': {
            color: 'purple'
        },
        '&$checked + $track': {
            backgroundColor: 'purple'
        }
    },
    checked: {},
    track: {}
})(Switch);

const ShadeSwitch = withStyles({
    switchBase: {
        color: 'yellow',
        '&$checked': {
            color: 'yellow'
        },
        '&$checked + $track': {
            backgroundColor: '#ffd300'
        }
    },
    checked: {},
    track: {}
})(Switch);

const NttSwitch = withStyles({
    switchBase: {
        color: '#fd8d3c',
        '&$checked': {
            color: '#fd8d3c'
        },
        '&$checked + $track': {
            backgroundColor: '#fd8d3c'
        }
    },
    checked: {},
    track: {}
})(Switch);

const GraipSwitch = withStyles({
    switchBase: {
        color: '#7b481c',
        '&$checked': {
            color: '#7b481c'
        },
        '&$checked + $track': {
            backgroundColor: '#7b481c'
        }
    },
    checked: {},
    track: {}
})(Switch);

const CulvertSwitch = withStyles({
    switchBase: {
        color: 'red',
        '&$checked': {
            color: 'red'
        },
        '&$checked + $track': {
            backgroundColor: 'red'
        }
    },
    checked: {},
    track: {}
})(Switch);

const TaxlotSwitch = withStyles({
    switchBase: {
        color: 'c62a0c',
        '&$checked': {
            color: 'c62a0c'
        },
        '&$checked + $track': {
            backgroundColor: 'c62a0c'
        }
    },
    checked: {},
    track: {}
})(Switch);

const MapFilter = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const location = useLocation();

    const open = Boolean(anchorEl);
    const id = open ? 'map-filter' : undefined;

    const [value, setValue] = useState('hs');

    const {
        map,
        layer,
        setLayer,
        hsData,
        hsFlowData,
        flowData,
        shadeData,
        nttData,
        graipData,
        culvertData,
        taxlotData,
        setChartId
    } = props;

    const {
        hsRange,
        setHsRange,
        hsFlowRange,
        setHsFlowRange,
        setFlowRange,
        shadeRange,
        setShadeRange,
        nttRange,
        setNttRange,
        graipRange,
        setGraipRange
    } = props;

    const {
        hsValue,
        setHsValue,
        hsFlowValue,
        setHsFlowValue,
        flowValue,
        setFlowValue,
        shadeValue,
        setShadeValue,
        nttValue,
        setNttValue,
        graipValue,
        setGraipValue
    } = props;

    useEffect(() => {
        if (hsData !== null) {
            initHsRange();
        }

        if (hsFlowData !== null) {
            initHsFlowRange();
        }

        if (shadeData !== null) {
            initShadeRange();
        }

        if (nttData !== null) {
            initNttRange();
        }

        if (graipData !== null) {
            initGraipRange();
        }

        if (flowData !== null) {
            initFlowRange();
        }

        if (location.pathname !== '/map') {
            setAnchorEl(null);
        }
    }, [hsData, flowData, hsFlowData, shadeData, nttData, graipData, location]);

    // initialize heat source range
    const initHsRange = () => {
        let min = 0;
        let max = 0;
        hsData.features.forEach((feature) => {
            if (feature.properties['hs_veg_sorted_id'] > max) {
                max = feature.properties['hs_veg_sorted_id'];
            }
        });
        setHsRange([min, max]);
    };

    // initialize heat source flow range
    const initHsFlowRange = () => {
        let min = 0;
        let max = 0;
        hsFlowData.features.forEach((feature) => {
            if (feature.properties['id'] > max) {
                max = feature.properties['id'];
            }
        });
        setHsFlowRange([min, max]);
    };

    const initFlowRange = () => {
        let min = 0;
        let max = 0;
        flowData.features.forEach((feature) => {
            if (feature.properties['flow_sorted_id'] > max) {
                max = feature.properties['flow_sorted_id'];
            }
        });
        setFlowRange([min, max]);
    };

    // initialize sediment range
    const initNttRange = () => {
        let min = 0;
        let max = 0;
        nttData.features.forEach((feature) => {
            if (parseInt(feature.properties['ntt_sorted_id']) > max) {
                max = parseInt(feature.properties['ntt_sorted_id']);
            }
        });
        setNttRange([min, max]);
    };

    // initialize shade range
    const initShadeRange = () => {
        let min = 0;
        let max = 0;
        shadeData.features.forEach((feature) => {
            if (parseInt(feature.properties['sorted_id']) > max) {
                max = parseInt(feature.properties['sorted_id']);
            }
        });
        setShadeRange([min, max]);
    };

    // initialize graip range
    const initGraipRange = () => {
        let min = 0;
        let max = 0;
        graipData.features.forEach((feature) => {
            if (parseInt(feature.properties['graip_sorted_id']) > max) {
                max = parseInt(feature.properties['graip_sorted_id']);
            }
        });
        setGraipRange([min, max]);
    };

    // toogle visibility of map layer
    const handleChange = (event) => {
        setLayer({ ...layer, [event.target.name]: event.target.checked });
        event.target.checked
            ? map.setLayoutProperty(event.target.name, 'visibility', 'visible')
            : map.setLayoutProperty(event.target.name, 'visibility', 'none');
    };

    const handlePlot = (event) => {
        setChartId(event.target.value);
        setValue(event.target.value);
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <Fab aria-describedby={id} className={classes.fab} onClick={handleClick}>
                <SettingsRoundedIcon />
            </Fab>

            <Popper id={id} open={open} anchorEl={anchorEl} className={classes.popover}>
                <div className={classes.title}>
                    <Typography component='legend'>Map Filter</Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Tooltip title='Close'>
                        <IconButton
                            aria-label='close'
                            className={classes.closeButton}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>

                <div className={classes.header}>
                    <Typography style={{ width: '85%' }} component='legend'>
                        Cost Efficiency
                    </Typography>
                    <Typography style={{ width: '15%', textAlign: 'center', color: '#4a94ff' }}>
                        Plot
                    </Typography>
                </div>

                <div className={classes.filter}>
                    <FormGroup className={classes.formGroup}>
                        <FormControlLabel
                            className={classes.formLabel}
                            control={
                                <FlowSwitch
                                    checked={layer['flow-layer']}
                                    onChange={handleChange}
                                    name='flow-layer'
                                    color='primary'
                                />
                            }
                            label={
                                <Typography style={{ fontSize: '0.875rem' }}>
                                    Steam Temperature <br /> (Water Rights)
                                </Typography>
                            }
                        />
                        <RangeSlider value={flowValue} setValue={setFlowValue} color='purple' />

                        <FormControlLabel
                            className={classes.formLabel}
                            control={
                                <HsFlowSwitch
                                    checked={layer['hs-flow-layer']}
                                    onChange={handleChange}
                                    name='hs-flow-layer'
                                    classes={{
                                        checked: classes.flowSwitch
                                    }}
                                />
                            }
                            label={
                                <Typography style={{ fontSize: '0.875rem' }}>
                                    Stream Temperature <br /> (Grouped Water Rights)
                                </Typography>
                            }
                        />
                        <RangeSlider value={hsFlowValue} setValue={setHsFlowValue} color='green' />

                        <FormControlLabel
                            className={classes.formLabel}
                            control={
                                <ShadeSwitch
                                    checked={layer['shade-layer']}
                                    onChange={handleChange}
                                    name='shade-layer'
                                    color='primary'
                                />
                            }
                            label={
                                <Typography style={{ fontSize: '0.875rem' }}>Solar Load</Typography>
                            }
                        />
                        <RangeSlider value={shadeValue} setValue={setShadeValue} color='#ffd300' />

                        <FormControlLabel
                            className={classes.formLabel}
                            control={
                                <NttSwitch
                                    checked={layer['ntt-layer']}
                                    onChange={handleChange}
                                    name='ntt-layer'
                                    color='primary'
                                />
                            }
                            label={
                                <Typography style={{ fontSize: '0.875rem' }}>
                                    Top Field Sediment
                                </Typography>
                            }
                        />
                        <RangeSlider value={nttValue} setValue={setNttValue} color='#fd8d3c' />

                        <FormControlLabel
                            className={classes.formLabel}
                            control={
                                <GraipSwitch
                                    checked={layer['graip-layer']}
                                    onChange={handleChange}
                                    name='graip-layer'
                                    color='primary'
                                />
                            }
                            label={
                                <Typography style={{ fontSize: '0.875rem' }}>
                                    Top Road Sediment
                                </Typography>
                            }
                        />
                        <RangeSlider value={graipValue} setValue={setGraipValue} color='#7b481c' />

                        <FormControlLabel
                            className={classes.formLabel}
                            control={
                                <HsSwitch
                                    checked={layer['hs-layer']}
                                    onChange={handleChange}
                                    name='hs-layer'
                                    classes={{
                                        checked: classes.hsSwitch
                                    }}
                                />
                            }
                            label={
                                <Typography style={{ fontSize: '0.875rem' }}>
                                    Stream Temperature (Veg)
                                </Typography>
                            }
                        />
                        <RangeSlider value={hsValue} setValue={setHsValue} color='#2086e6' />

                        <div style={{ display: 'flex' }}>
                            <FormControlLabel
                                className={classes.formLabel}
                                control={
                                    <CulvertSwitch
                                        checked={layer['culvert-layer']}
                                        onChange={handleChange}
                                        name='culvert-layer'
                                        classes={{
                                            checked: classes.culvertSwitch
                                        }}
                                    />
                                }
                                label={
                                    <Typography style={{ fontSize: '0.875rem' }}>
                                        Culverts
                                    </Typography>
                                }
                            />
                            <div style={{ flexGrow: 1 }} />
                            <FormControlLabel
                                className={classes.formLabel}
                                control={
                                    <TaxlotSwitch
                                        checked={layer['taxlot-layer']}
                                        onChange={handleChange}
                                        name='taxlot-layer'
                                        classes={{
                                            checked: classes.taxlotSwitch
                                        }}
                                    />
                                }
                                label={
                                    <Typography style={{ fontSize: '0.875rem' }}>
                                        Taxlots
                                    </Typography>
                                }
                            />
                        </div>
                    </FormGroup>
                    <div>
                        <FormControl component='fieldset' style={{ marginTop: '30px' }}>
                            <RadioGroup
                                aria-label='plots'
                                name='plots'
                                value={value}
                                onChange={handlePlot}
                            >
                                <FormControlLabel
                                    style={{ marginRight: '0' }}
                                    value='flow'
                                    control={
                                        <Radio
                                            classes={{
                                                root: classes.flowRadio,
                                                checked: classes.checked
                                            }}
                                        />
                                    }
                                    label=''
                                />

                                <FormControlLabel
                                    style={{ marginRight: '0' }}
                                    value='hsflow'
                                    control={
                                        <Radio
                                            classes={{
                                                root: classes.hsFlowRadio,
                                                checked: classes.checked
                                            }}
                                        />
                                    }
                                    label=''
                                />
                                <FormControlLabel
                                    style={{ marginRight: '0' }}
                                    value='shade'
                                    control={
                                        <Radio
                                            classes={{
                                                root: classes.shadeRadio,
                                                checked: classes.checked
                                            }}
                                        />
                                    }
                                    label=''
                                />
                                <FormControlLabel
                                    style={{ marginRight: '0' }}
                                    className={classes.radioContainer}
                                    value='ntt'
                                    control={
                                        <Radio
                                            classes={{
                                                root: classes.nttRadio,
                                                checked: classes.checked
                                            }}
                                        />
                                    }
                                    label=''
                                />
                                <FormControlLabel
                                    style={{ marginRight: '0' }}
                                    className={classes.radioContainer}
                                    value='graip'
                                    control={
                                        <Radio
                                            classes={{
                                                root: classes.graipRadio,
                                                checked: classes.checked
                                            }}
                                        />
                                    }
                                    label=''
                                />
                                <FormControlLabel
                                    style={{ marginRight: '0' }}
                                    value='hs'
                                    control={
                                        <Radio
                                            classes={{
                                                root: classes.hsRadio,
                                                checked: classes.checked
                                            }}
                                        />
                                    }
                                    label=''
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </Popper>
        </div>
    );
};

export default MapFilter;
