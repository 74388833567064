import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import DataGrid from 'react-data-grid';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useWindowDimensions from '../Dimensions/WindowDimensions';
import TableHeader from './TableHeader';
import Filled from '../Filled/Filled';
import TableModal from './TableModal/TableModal';
import TableLoading from './Loading';
import { Filter } from './Filters';
import { activateFeature } from '../lib/maplib';
import { sortBy } from 'underscore';
import { formatNumber, formatPrice, roundString, formatDate } from '../lib/formatlib';
import styles from './style.css';

const useStyles = makeStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        zIndex: 3
    },
    headerCell: {
        color: '#3c3b3f',
        fontSize: ' 0.875rem',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        '&:after': {
            content: "''",
            position: 'absolute',
            borderLeft: '2px solid rgb(220, 227, 233)',
            top: '35%',
            left: '0',
            height: '40%',
            marginTop: 'auto',
            marginBottom: 'auto'
        }
    },
    grid: {
        height: '100%',
        overflowY: 'hidden',
        position: 'relative'
    }
});

const createData = (data, setRange) => {
    const filterData = [];
    const range = [0, 0];

    data.features.forEach((value) => {
        value.properties['coordinates'] = value.geometry.coordinates;
        value.properties['flow_sorted_id'] = parseInt(value.properties['flow_sorted_id']);
        value.properties['maint_level'] = parseInt(value.properties['maint_level']);
        value.properties['miles'] = roundString(value.properties['miles'], 2);
        value.properties['delta_sed_deli'] = roundString(value.properties['delta_sed_deli'], 1);
        value.properties['efficiency'] = roundString(value.properties['efficiency'], 3);
        value.properties['acre_ft'] = roundString(value.properties['acre_ft'], 3);
        value.properties['ndvi_mean'] = roundString(value.properties['ndvi_mean'], 3);        
        value.properties['degree_hour_decrease'] = !value.properties['degree_hour_decrease']
            ? Number.NEGATIVE_INFINITY
            : roundString(value.properties['degree_hour_decrease'], 1);
        value.properties['total_cost'] = !value.properties['total_cost']
            ? Number.NEGATIVE_INFINITY
            : roundString(value.properties['total_cost'], 0);
        value.properties['efficiency'] = roundString(value.properties['efficiency'], 3);

        // configure range
        if (value.properties['efficiency'] > range[1]) {
            range[1] = value.properties['efficiency'];
        }

        filterData.push(value.properties);
    });

    setRange(range);
    return filterData;
};

const FlowTable = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { height, width } = useWindowDimensions();
    const { user, map, data, setNavBarSync } = props;

    const [rows, setRows] = useState([]);
    const [rowsCopy, setRowsCopy] = useState([]);
    const [selected, setSelected] = useState(new Set());

    const [range, setRange] = useState([0, 0]);
    const [sortColumn, setSortColumn] = useState('efficiency');
    const [sortDirection, setSortDirection] = useState('DESC');

    const [options, setOptions] = useState({});
    const [cost, setCost] = useState(0);
    const [tempImpact, setTempImpact] = useState(0);

    const [open, setOpen] = useState(false);
    let padding = width > 1540 ? (width - 1540 - 20) / 8 : 0;

    useEffect(() => {
        if (data !== null) {
            const cleanData = createData(data, setRange);
            setRows(cleanData);
            setRowsCopy(cleanData);
        }
    }, [data]);

    const ActionCell = () => {
        const handleClick = () => {
            setSelected(new Set());
            setTempImpact(0);
            setCost(0);
        };

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    color: '#3c3b3f',
                    fontSize: ' 0.875rem',
                    fontWeight: 500
                }}
            >
                <Checkbox
                    style={{ padding: '0px', margin: 'auto' }}
                    color='primary'
                    indeterminate={selected.size !== 0}
                    checked={selected.size !== 0}
                    onClick={handleClick}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Tooltip title='Locate'>
                    <IconButton style={{ padding: 0, margin: 'auto' }} aria-label='sort'>
                        <PinDropIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const FilterCell = ({ column }) => {
        return (
            <div className={classes.headerCell}>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={column.sortable ? () => sort(column.key) : () => {}}
                >
                    {column.name}
                </span>

                {sortColumn === column.key && sortDirection === 'ASC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowUpwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                {sortColumn === column.key && sortDirection === 'DESC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowDownwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                <Filter
                    rows={rowsCopy}
                    setRows={setRows}
                    options={options}
                    setOptions={setOptions}
                    column={column.key}
                />
            </div>
        );
    };

    const HeaderCell = ({ column }) => {
        return (
            <div className={classes.headerCell}>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={column.sortable ? () => sort(column.key) : () => {}}
                >
                    {column.name}
                </span>

                {sortColumn === column.key && sortDirection === 'ASC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowUpwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}

                {sortColumn === column.key && sortDirection === 'DESC' && (
                    <IconButton
                        onClick={() => sort(column.key)}
                        style={{ padding: '4px' }}
                        aria-label='sort'
                    >
                        <ArrowDownwardIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                )}
            </div>
        );
    };

    const columns = [
        {
            width: 120,
            name: 'Select',
            key: 'Select',
            formatter: (data) => SelectFormatter(data),
            headerRenderer: () => ActionCell()
        },
        {
            width: 100 + padding,
            name: 'Rank',
            key: 'flow_sorted_id',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 180 + padding,
            name: 'Temp. Impact (deg*hrs)',
            key: 'degree_hour_decrease',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 140 + padding,
            name: 'Cost ($)',
            key: 'total_cost',
            formatter: (data) => PriceFormatter(data.row.total_cost),
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 200 + padding,
            name: 'Efficiency (deg*hrs/$)',
            key: 'efficiency',
            formatter: (data) => FillFormatter(data),
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 160 + padding,
            name: 'Certificate Number',
            key: 'water_right_id',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 180 + padding,
            name: 'Priority Date',
            key: 'priority_date',
            sortable: true,
            formatter: (data) => DateFormatter(data.row.priority_date),
            headerRenderer: HeaderCell
        },
        {
            width: 130 + padding,
            name: 'Stream',
            key: 'stream',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 130 + padding,
            name: 'River (km)',
            key: 'river_km',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 130 + padding,
            name: 'CFS',
            key: 'cfs',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 120 + padding,
            name: 'Acres',
            key: 'acres',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 120 + padding,
            name: 'AF',
            key: 'acre_ft',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 200 + padding,
            name: 'Scenario',
            key: 'scenario',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 120 + padding,
            name: 'NDVI Mean',
            key: 'ndvi_mean',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 120 + padding,
            name: 'Duty',
            key: 'duty',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 150 + padding,
            name: 'Watershed',
            key: 'watershed',
            sortable: true,
            headerRenderer: HeaderCell
        },
        {
            width: 180 + padding,
            name: 'Subwatershed',
            key: 'subwatershed',
            sortable: true,
            headerRenderer: HeaderCell
        }
    ];

    const NumberFormatter = (value) => {
        return <span>{formatNumber(value, 1)} </span>;
    };

    const DateFormatter = (value) => {
        return <span>{formatDate(value)}</span>;
    };

    const PriceFormatter = (value) => {
        return <span>${formatPrice(value)} </span>;
    };

    const FillFormatter = (data) => {
        return (
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    lineHeight: '27px',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <Filled value={data.row.efficiency} min={range[0]} max={range[1]} projectRank={data.row.flow_sorted_id} totalProjects={rows.length}/>
            </div>
        );
    };

    const SelectFormatter = (data) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Checkbox
                    style={{ padding: '0px', margin: 'auto' }}
                    color='primary'
                    checked={selected.has(data.row.id)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />

                <Tooltip title='Locate'>
                    <IconButton
                        onClick={() => handleLocateClick(data)}
                        style={{ padding: 0, margin: 'auto' }}
                        aria-label='sort'
                    >
                        <PinDropIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const handleRowClick = (index, data) => {
        setSelected((state) => {
            if (state.has(data.id)) {
                setCost(cost - data.total_cost);
                setTempImpact(tempImpact - data.degree_hour_decrease);
                return new Set([...state].filter((x) => x !== data.id));
            } else {
                setCost(cost + data.total_cost);
                setTempImpact(tempImpact + data.degree_hour_decrease);
                return new Set(state).add(data.id);
            }
        });
    };

    const handleLocateClick = (data) => {
        activateFeature(map, 'flow-layer', data.row.coordinates, data.row);
        history.push('/map');
        setNavBarSync(true);
    };

    const sort = (value) => {
        setRows(sortDirection === 'ASC' ? sortBy(rows, value).reverse() : sortBy(rows, value));
        setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        setSortColumn(value);
    };

    return (
        <div className={classes.root}>
            <TableHeader
                map={map}
                title={'Stream Temperature (Water Rights)'}
                info={[
                    { name: 'Temp. Impact (deg*hrs)', value: formatNumber(tempImpact, 0) },
                    { name: 'Cost', value: formatPrice(cost) }
                ]}
                data={data}
                rows={rows}
                setRows={setRows}
                selected={selected}
                handleAdd={() => setOpen(true)}
            />

            <div className={classes.grid}>
                <TableLoading loading={data === null} empty={rows.length === 0} />
                <DataGrid
                    rowKey='id'
                    columns={columns}
                    rows={rows}
                    onRowClick={handleRowClick}
                    headerRowHeight={50}
                    rowHeight={38}
                />
            </div>

            <TableModal
                type='flow'
                user={user}
                selected={selected}
                setSelected={setSelected}
                open={open}
                setOpen={setOpen}
                onAdd={() => {
                    setCost(0);
                    setTempImpact(0);
                }}
            />
        </div>
    );
};

export default FlowTable;
