import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ProjectsTable from '../Table/ProjectsTable';
import { useApi } from '../../Api';

const useStyles = makeStyles((theme) => ({
    progress: {
        position: 'absolute',
        textAlign: 'center',
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)',
        marginRight: '-50%',
        zIndex: '3'
    },
    dialog: {
        padding: '0px',
        minWidth: '800px',
        minHeight: '600px',
        maxWidth: 'none'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0'
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'right',
        padding: '0px'
    },
    position: {
        postion: 'absolute'
    },
    margin: {
        margin: 'auto'
    }
}));

const ProgramModal = (props) => {
    const api = useApi();
    const classes = useStyles();
    const timer = useRef();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);

    const [visual, setVisual] = useState(false);
    const [graphPath, setGraphPath] = useState('');

    const { map, open, setOpen, mode, program, setProgram, setNavBarSync } = props;

    useEffect(() => {
        setLoading(true);
        if (Object.keys(program).length !== 0) {
            const response = api.fetchProjects(program['program_id']);
            response.then((value) => {
                timer.current = setTimeout(() => {
                    setData(value.data.data);
                    setLoading(false);
                }, 500);
            });
        }
    }, [program, open, update]);

    const handleClose = () => {
        setVisual(false);
        setOpen(false);
        setData([]);
        setProgram({});
    };

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby='customized-dialog-title'
                open={open}
                classes={{
                    paperWidthSm: classes.dialog
                }}
            >
                <MuiDialogTitle className={classes.dialogTitle}>
                    <IconButton aria-label='close' onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <MuiDialogContent className={classes.dialogContent} dividers>
                    {loading && <CircularProgress className={classes.progress} />}

                    {visual ? (
                        <React.Fragment>
                            <div className={classes.position}>
                                <IconButton onClick={() => setVisual(false)} aria-label='delete'>
                                    <ArrowBackIcon fontSize='small' />
                                </IconButton>
                            </div>
                            <img
                                src={graphPath}
                                className={classes.margin}
                                width={800}
                                height={320}
                            />
                        </React.Fragment>
                    ) : (
                        <div
                            style={{
                                height: '100%',
                                backgroundColor: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1
                            }}
                        >
                            <ProjectsTable
                                map={map}
                                data={data}
                                title={program.program_name}
                                setVisual={setVisual}
                                setGraphPath={setGraphPath}
                                setNavBarSync={setNavBarSync}
                                update={update}
                                setUpdate={setUpdate}
                                mode={mode}
                            />
                        </div>
                    )}
                </MuiDialogContent>
            </Dialog>
        </div>
    );
};
export default ProgramModal;
