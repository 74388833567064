import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Alert from '../Alert/Alert';
import AlertProvider from '../contexts/AlertProvider';
import { useSaveTokensFromQuery } from '../../auth/useSaveTokensFromQuery';
import { useVerifyLogIn } from '../../auth/useVerifyLogIn';

import dstImage from '../../img/dst.png';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        zIndex: 3
    },
    container: {
        display: 'flex',
        height: '100%'
    },
    infoItem: {
        display: 'flex',
        width: '100%'
    },
    info: {
        width: '100%',
        margin: 'auto auto',
        maxWidth: 550
    },
    imageItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 600,
        margin: '0px auto'
    },
    image: {
        flex: '0 0 100%',
        maxHeight: '400px',
        backgroundImage: `url(${dstImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '10px'
    },
    header: {
        lineHeight: '1.2',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 500,
        margin: '16px 0px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5rem'
        }
    },
    subHeader: {
        lineHeight: '22px',
        letterSpacing: '-0.01em',
        fontWeight: '400',
        color: '#6e828a',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1em'
        }
    },
    line: {
        color: '#fff',
        marginBottom: '1.5rem',
        margintop: '1.5rem'
    },
    button: {
        color: '#007afc',
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: 400,
        border: '1px solid #61adff',
        '&:hover': {
            backgroundColor: 'rgba(160, 210, 255, 0.05)'
        }
    }
}));

const Home = (props) => {
    useSaveTokensFromQuery();
    const classes = useStyles();
    const history = useHistory();
    const alert = useContext(AlertProvider.context);
    const isLogIn = useVerifyLogIn();
    const { setNavBarSync } = props;

    const handleClick = () => {
        if (!isLogIn) {
            alert.setAlert({ status: 'login' });
        } else {
            history.push('/map');
            setNavBarSync(true);
        }
    };

    return (
        <div className={classes.root}>
            <Container maxWidth='xl' className={classes.container}>
                <Grid container spacing={0}>
                    <Grid item md={6} className={classes.infoItem}>
                        <div className={classes.info}>
                            <Typography className={classes.header}>
                                Rogue Basin Decision Support Tool
                            </Typography>

                            <Typography variant='body1' style={{ marginBottom: 16, lineHeight: '2rem' }}>
                                Use analytics to explore and evaluate project-level impacts and
                                costs of actions to address temperature, solar loading, sediment and
                                nutrient loading in priority watersheds, export prioritized results
                                and build watershed programs. Created with support from the Unites States Forest Service.
                            </Typography>

                            <hr className={classes.line} />
                            <Button
                                onClick={handleClick}
                                size='medium'
                                variant='outlined'
                                className={classes.button}
                            >
                                Go To Map Explorer
                            </Button>
                        </div>
                    </Grid>

                    <Grid item md={6} className={classes.imageItem}>
                        <div className={classes.image} />
                    </Grid>
                </Grid>

                <Alert status={alert.state.status} setNavBarSync={setNavBarSync} />
            </Container>

            <Typography style={{ textAlign: 'center', marginBottom: 32 }}>
                {' '}
                © 2022 The Freshwater Trust
            </Typography>
        </div>
    );
};

export default Home;
