import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import TimelineIcon from '@material-ui/icons/Timeline';
import Fab from '@material-ui/core/Fab';
import Chart from './Chart';
import { useLocation } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    paper: {
        zIndex: 1200
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        zIndex: 4
    },
    buttonContainer: {
        position: 'absolute',
        zIndex: '2',
        right: '2%',
        bottom: '4%'
    },
    graphContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '400px',
        width: '650px',
        padding: '16px 16px 0px 8px'
    }
}));

const ChartFilter = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const location = useLocation();

    const { map, syncChart, setSyncChart, chartId } = props;

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (location.pathname !== '/map') {
            setAnchorEl(null);
        }
    }, [location]);

    const handleSync = (e) => {
        //console.log(e);
        setSyncChart(true);
    };

    const handleOpen = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        if (map !== null) {
            //console.log('binding event');
            map.on('moveend', handleSync);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (map !== null) {
            //console.log('unbinding event');
            map.off('moveend', handleSync);
        }
    };

    return (
        <div aria-describedby={id} className={classes.buttonContainer}>
            <Fab onClick={handleOpen}>
                <TimelineIcon />
            </Fab>

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                style={{ background: '#fff', borderRadius: '4px', zIndex: 3 }}
            >
                <Tooltip title='Close'>
                    <IconButton
                        aria-label='close'
                        className={classes.closeButton}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
                <div className={classes.graphContainer}>
                    <Chart
                        map={map}
                        chartId={chartId}
                        syncChart={syncChart}
                        setSyncChart={setSyncChart}
                    />
                </div>
            </Popper>
        </div>
    );
};

export default ChartFilter;
