import React, { useEffect, useState, useRef } from 'react';
import ChartFilter from '../Filters/ChartFilter/ChartFilter';
import SubWaterFilter from '../Filters/SubWaterFilter/SubWaterFilter';
import MapType from '../Filters/MapType/MapType';
import MapFilter from '../Filters/MapFilter/MapFilter';
import Card from './Card/Card';

const Filters = (props) => {
    const {
        map,
        hsData,
        flowData,
        hsFlowData,
        shadeData,
        nttData,
        graipData,
        watershedData,
        subwaterData,
        culvertData,
        taxlotData,
        hoveredSubwater,
        hoveredWatershed,
        hoveredTaxlot
    } = props;

    // filter syncronization
    const [syncFilter, setSyncFilter] = useState(false);
    const timer = useRef();

    // chart syncronization
    const [syncChart, setSyncChart] = useState(false);
    const [chartId, setChartId] = useState('hs');

    // layer toogle
    const [layer, setLayer] = useState({
        'hs-layer': true,
        'hs-flow-layer': true,
        'flow-layer': true,
        'shade-layer': true,
        'ntt-layer': true,
        'graip-layer': true,
        'culvert-layer': true,
        'taxlot-layer': true
    });

    // Range values
    const [hsRange, setHsRange] = useState([0, 0]);
    const [hsFlowRange, setHsFlowRange] = useState([0, 0]);
    const [shadeRange, setShadeRange] = useState([0, 0]);
    const [nttRange, setNttRange] = useState([0, 0]);
    const [graipRange, setGraipRange] = useState([0, 0]);
    const [flowRange, setFlowRange] = useState([0, 0]);
    const [culvertRange, setCulvertRange] = useState([0, 0]);
    const [taxlotRange, setTaxlotRange] = useState([0, 0]);

    // Selected values
    const [hsValue, setHsValue] = useState([0, 100]);
    const [hsFlowValue, setHsFlowValue] = useState([0, 100]);
    const [shadeValue, setShadeValue] = useState([0, 100]);
    const [nttValue, setNttValue] = useState([0, 100]);
    const [graipValue, setGraipValue] = useState([0, 100]);
    const [flowValue, setFlowValue] = useState([0, 100]);
    const [culvertValue, setCulvertValue] = useState([0, 100]);
    const [taxlotValue, setTaxlotValue] = useState([0, 100]);

    const [watershed, setWatershed] = useState({
        'All watersheds': true,
        'Middle Rogue': true,
        'Upper Rogue': true,
        'Applegate River': true,
        'Illinois River': true,
        'Lower Rogue': true
    });

    const [subwater, setSubwater] = useState({
        'Antelope Creek': true,
        'East Fork Illinois River': true,
        'Elk Creek': true,
        'Evans Creek': true,
        'Little Butte & North Fork Little Butte Creek': true,
        'South Fork Little Butte Creek': true,
        'Sucker Creek': true,
        'Other Upper Rogue': true,
        'Other Illinois River': true,
        'Other Applegate River': true,
        'Other Middle Rogue': true,
        'Other Lower Rogue': true
    });

    const parent = {
        'Antelope Creek': 'Upper Rogue',
        'Elk Creek': 'Upper Rogue',
        'Little Butte & North Fork Little Butte Creek': 'Upper Rogue',
        'South Fork Little Butte Creek': 'Upper Rogue',
        'East Fork Illinois River': 'Illinois River',
        'Sucker Creek': 'Illinois River',
        'Evans Creek': 'Middle Rogue',
        'Other Upper Rogue': 'Upper Rogue',
        'Other Illinois River': 'Illinois River',
        'Other Applegate River': 'Applegate River',
        'Other Middle Rogue': 'Middle Rogue',
        'Other Lower Rogue': 'Lower Rogue'
    };

    const buildFilter = (value, range, property) => {
        const filter = ['any'];

        Object.keys(subwater).forEach((key) => {
            // is subwatershed marked or unmarked
            const marked = subwater[key];
            // is parent marked or unmarked
            const activeParent = watershed[parent[key]];
            // is "All watersheds marked" or unmarked
            const renderAll = watershed['All watersheds'];

            const conditions = ['all', ['==', ['get', 'subwatershed'], key]];

            if (value && range && property) {
                // the projects that will be render need to fullfill the following requirement:
                // min < project.sortedid < max
                let min = ((100 - value[1]) * range[1]) / 100;
                let max = ((100 - value[0]) * range[1]) / 100;

                conditions.push(
                    ['>', ['to-number', ['get', property]], min],
                    ['<', ['to-number', ['get', property]], max]
                );
            }

            if (marked && activeParent && renderAll) {
                filter.push(conditions);
            }
        });
        return filter;
    };

    useEffect(() => {
        if (map !== null) {
            if (map.getLayer('hs-layer')) {
                map.setFilter('hs-layer', buildFilter(hsValue, hsRange, 'hs_veg_sorted_id'));
                map.setFilter('hs-layer-rank', buildFilter(hsValue, hsRange, 'hs_veg_sorted_id'));
            }
            if (map.getLayer('flow-layer')) {
                map.setFilter('flow-layer', buildFilter(flowValue, flowRange, 'flow_sorted_id'));
            }
            if (map.getLayer('hs-flow-layer')) {
                map.setFilter('hs-flow-layer', buildFilter(hsFlowValue, hsFlowRange, 'id'));
            }
            if (map.getLayer('shade-layer')) {
                map.setFilter('shade-layer', buildFilter(shadeValue, shadeRange, 'sorted_id'));
                map.setFilter('shade-layer-rank', buildFilter(shadeValue, shadeRange, 'sorted_id'));
            }
            if (map.getLayer('ntt-layer')) {
                map.setFilter('ntt-layer', buildFilter(nttValue, nttRange, 'ntt_sorted_id'));
                map.setFilter('ntt-layer-rank', buildFilter(nttValue, nttRange, 'ntt_sorted_id'));
            }
            if (map.getLayer('graip-layer')) {
                map.setFilter(
                    'graip-layer',
                    buildFilter(graipValue, graipRange, 'graip_sorted_id')
                );
            }
            if (map.getLayer('culvert-layer')) {
                map.setFilter('culvert-layer', buildFilter());
            }
            if (map.getLayer('taxlot-layer')) {
                map.setFilter('taxlot-layer', buildFilter());
            }

            if (syncFilter) {
                Object.keys(layer).forEach((key) => {
                    layer[key]
                        ? map.setLayoutProperty(key, 'visibility', 'visible')
                        : map.setLayoutProperty(key, 'visibility', 'none');
                });
                setSyncFilter(false);
            }

            timer.current = setTimeout(() => {
                setSyncChart(true);
            }, 1000);
        }
    }, [
        map,
        syncFilter,
        hsValue,
        flowValue,
        hsFlowValue,
        shadeValue,
        nttValue,
        graipValue,
        watershed,
        subwater
    ]);

    return (
        <div>
            <MapFilter
                map={map}
                layer={layer}
                setLayer={setLayer}
                hsData={hsData}
                hsFlowData={hsFlowData}
                flowData={flowData}
                shadeData={shadeData}
                nttData={nttData}
                graipData={graipData}
                culvertData={culvertData}
                taxlotData={taxlotData}
                hsRange={hsRange}
                setHsRange={setHsRange}
                hsFlowRange={hsFlowRange}
                setHsFlowRange={setHsFlowRange}
                flowRange={flowRange}
                setFlowRange={setFlowRange}
                shadeRange={shadeRange}
                setShadeRange={setShadeRange}
                nttRange={nttRange}
                setNttRange={setNttRange}
                graipRange={graipRange}
                setGraipRange={setGraipRange}
                hsValue={hsValue}
                setHsValue={setHsValue}
                hsFlowValue={hsFlowValue}
                setHsFlowValue={setHsFlowValue}
                flowValue={flowValue}
                setFlowValue={setFlowValue}
                shadeValue={shadeValue}
                setShadeValue={setShadeValue}
                nttValue={nttValue}
                setNttValue={setNttValue}
                graipValue={graipValue}
                setGraipValue={setGraipValue}
                setChartId={setChartId}
                setSyncChart={setSyncChart}
            />

            <SubWaterFilter
                map={map}
                watershed={watershed}
                setWatershed={setWatershed}
                subwater={subwater}
                setSubwater={setSubwater}
            />

            <MapType
                map={map}
                hsData={hsData}
                flowData={flowData}
                hsFlowData={hsFlowData}
                shadeData={shadeData}
                nttData={nttData}
                graipData={graipData}
                culvertData={culvertData}
                taxlotData={taxlotData}
                watershedData={watershedData}
                subwaterData={subwaterData}
                setSyncFilter={setSyncFilter}
            />
            <ChartFilter
                map={map}
                chartId={chartId}
                syncChart={syncChart}
                setSyncChart={setSyncChart}
            />
            <Card
                map={map}
                hoveredWatershed={hoveredWatershed}
                hoveredSubwater={hoveredSubwater}
                hoveredTaxlot={hoveredTaxlot}
                showTaxlot={layer['taxlot-layer']}
            />
        </div>
    );
};

export default Filters;
