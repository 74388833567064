import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        backgroundColor: '#fff',
        padding: '24px'
    }
}));

const About = () => {
    const classes = useStyles();

    return (
        <Container maxWidth='lg' className={classes.root}>
            <Typography variant='h5'>About This Tool</Typography>

            <div style={{ margin: '16px 0' }}>
                <ol>
                    <li>
                        <Typography>
                            <Link href='#purpose'>Purpose</Link>
                        </Typography>
                    </li>

                    <li>
                        <Typography>
                            <Link href='#tool-components'>Tool Components</Link>
                        </Typography>

                        <ol type='a'>
                            <li>
                                <Typography>
                                    {' '}
                                    <Link href='#map-explorer-tab'> Map Explorer Tab </Link>
                                </Typography>
                            </li>

                            <li>
                                <Typography>
                                    {' '}
                                    <Link href='#table-tab'> Table Tab </Link>
                                </Typography>
                            </li>

                            <li>
                                <Typography>
                                    <Link href='#programs-tab'> Programs Tab</Link>
                                </Typography>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <Typography>
                            <Link href='#model-&-analyses'>Models & Analyses</Link>
                        </Typography>

                        <ol type='a'>
                            <li>
                                <Typography>
                                    <Link href='#heat-source-temperature-impacts'>
                                        Heat Source: Temperature Impacts
                                    </Link>
                                </Typography>

                                <ol type='a'>
                                    <li>
                                        <Typography>
                                            <Link href='#model-overview'>Model Overview</Link>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            <Link href='#riparian-planting-analysis'>
                                                Riparian Planting Analysis
                                            </Link>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            <Link href='#water-rights-and-groupd-water-rights-analysis'>
                                                Water Rights and Grouped Water Rights Analysis
                                            </Link>
                                        </Typography>
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <Typography>
                                    <Link href='#shade-a-lator-solar-load-for-riparian-planting'>
                                        Shade-a-lator: Solar Load for Riparian Plantings
                                    </Link>
                                </Typography>
                            </li>

                            <li>
                                <Typography>
                                    <Link href='#ntt-field-sediment'>NTT: Field Sediment </Link>
                                </Typography>
                            </li>

                            <li>
                                <Typography>
                                    <Link href='#graip-lite-road-sediment'>
                                        GRAIP Lite: Road Sediment
                                    </Link>
                                </Typography>
                            </li>

                            <li>
                                <Typography>
                                    <Link href='#culvert-risk-analysis'>Culvert Risk Analysis</Link>
                                </Typography>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>

            <div style={{ margin: '16px 0' }}>
                <Typography id='purpose' style={{ fontWeight: 500, margin: '8px 0' }}>
                    1. Purpose
                </Typography>
                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                    The objective of this tool is to demonstrate a system that allows users to
                    explore prioritized potential projects for a range of restoration outcomes,
                    based on project-level modeled uplift and costs, to improve downstream water
                    quality outcomes. The goal is to support the organization of restoration
                    programs and funding initiatives by providing basin-scale analytics that
                    assemble the expected costs and outcomes of a slate of prioritized projects.
                    This tool is oriented towards water quality including temperature, solar loading
                    and sediment/nutrients and has three major components described below. Users
                    should consider the technical strengths and limitations of each analysis and its
                    underlying model and data when considering their results.{' '}
                </Typography>
            </div>

            <div id='tool-components'>
                <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                    2. Tool Components
                </Typography>

                <ul type='none'>
                    <li id='map-explorer-tab'>
                        <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                            a. Map Explorer Tab
                        </Typography>
                        <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                            The objective of this tool is to demonstrate a system that allows users
                            to explore prioritized potential projects for a range of restoration
                            outcomes, based on project-level modeled uplift and costs, to improve
                            downstream water quality outcomes. The goal is to support the
                            organization of restoration programs and funding initiatives by
                            providing basin-scale analytics that assemble the expected costs and
                            outcomes of a slate of prioritized projects. This tool is oriented
                            towards water quality including temperature, solar loading and
                            sediment/nutrients and has three major components described below. Users
                            should consider the technical strengths and limitations of each analysis
                            and its underlying model and data when considering their results.
                        </Typography>
                    </li>
                    <li id='table-tab'>
                        <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                            b. Table Tab
                        </Typography>
                        <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                            The table view provides the user with an alternative way of exploring
                            the quantified outcomes, costs, and efficiencies of potential projects,
                            as well as locate projects. Users can select the table for the outcome
                            or analysis of interest, filter results by watershed, and view project
                            attributes including rank, uplift, cost, efficiency, and location. Here,
                            users can create programs by adding projects to a “shopping cart” as
                            well as export results of the analyses into Excel.
                        </Typography>
                    </li>
                    <li id='programs-tab'>
                        <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                            c. Programs Tab{' '}
                        </Typography>
                        <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                            Programs are either public (any user can view) or private (only viewable
                            by the specific user). In the program tab users can see the total cost
                            and uplift statistics for each individual program. Programs can include
                            all types of projects including riparian shade, flow restoration on-farm
                            upgrades and road improvement projects. The “inspect” button allows a
                            user to explore the projects that comprise a specific program and view
                            attenuated uplift graphs of temperature impacts for any riparian
                            planting project by reach through the summer months.
                        </Typography>
                    </li>
                </ul>
            </div>

            <div id='model-&-analyses'>
                <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                    3. Models & Analyses
                </Typography>

                <ul type='none' style={{ fontWeight: 500 }}>
                    <li id='heat-source-temperature-impacts'>
                        <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                            a. Heat Source: Temperature Impacts{' '}
                        </Typography>
                        <ul type='none' style={{ fontWeight: 500 }}>
                            <li id='model-overview'>
                                <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                                    I. Model Overview{' '}
                                </Typography>
                                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                    TFT incorporated results from five existing Heatsource models
                                    that were developed by ODEQ for temperature based TMDL’s during
                                    2001 and 2003 (Evans Creek [2003], Elk Creek [2001], Antelope
                                    Creek [2001], Little Butte Creek [2001], South Fork Little Butte
                                    Creek [2003]). In addition, TFT built and calibrated a new
                                    Heatsource model for the East Fork Illinois River using data
                                    collected from July 1 – August 31, 2019. Heatsource is an
                                    empirical model that simulates stream thermodynamics and
                                    hydrology, using observed stream temperature, flow rate and
                                    channel dimension data as well as riparian vegetation heights
                                    and densities. The unit of analysis for each model is
                                    stream-centerline derived nodes, spaced 0.25km apart throughout
                                    the model domain(s).{' '}
                                </Typography>
                            </li>
                            <li id='riparian-planting-analysis'>
                                <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                                    II. Riparian Planting Analysis
                                </Typography>
                                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                    TFT analyzed the temperature impact of riparian planting
                                    projects for each Heatsource model. At each node in a Heatsource
                                    model, riparian conditions at the time of model creation &
                                    calibration are recorded in seven directional transects (NE, E,
                                    SE, S, SW, W, NW), with four vegetation samples per transect. To
                                    determine if riparian conditions can be improved at a given
                                    node, a threshold of 10m was used. If vegetation was less than
                                    10m at a vegetation sample, the height was changed to 30m and
                                    the model was run, comparing the output stream temperature to
                                    the baseline. The metric used to compare each scenario to the
                                    baseline data is degree hours, which summarizes the change in
                                    temperature in degrees Celsius throughout the modeled time
                                    period (July 1 – Aug 31). To simulate full-scale riparian
                                    projects, TFT grouped adjacent modeled nodes where riparian
                                    conditions can be improved.
                                </Typography>
                            </li>
                            <li id='water-rights-and-groupd-water-rights-analysis'>
                                <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                                    III. Water Rights and Grouped Water Rights Analysis{' '}
                                </Typography>
                                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                    TFT analyzed the temperature impact of increasing streamflow
                                    through instream leasing in each Heatsource model, using two
                                    different approaches. TFT used water rights data from OWRD,
                                    selecting out only surface water rights for irrigation along the
                                    modeled rivers and associated tributaries. First, a coarse
                                    tributary-based analysis was conducted, where the flow rate of
                                    all diversions for a given tributary were summed and added to
                                    the model, using the degree hour metric to analyze impact (TFT
                                    used the USGS Stream Stats tool to determine natural flow
                                    conditions for July and August for each tributary scenario,
                                    where the mean value for July and August represents the CFS
                                    limit for additional flow rates). A second analysis was
                                    conducted where individual points of diversion and their
                                    associated flow rates were modeled as scenarios, again comparing
                                    the degree hour impact to baseline conditions.{' '}
                                </Typography>
                            </li>
                        </ul>
                    </li>
                    <li id='shade-a-lator-solar-load-for-riparian-planting'>
                        <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                            b. Shade-a-lator: Solar Load for Riparian Plantings
                        </Typography>
                        <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                            TFT analyzed the potential thermal load abatement at the parcel level
                            along nineteen rivers and streams within the Rogue Basin, using the
                            Shade-a-lator module of Heatsource. Shade-a-lator measures the incoming
                            solar radiation at stream centerline nodes, spaced 25m apart, accounting
                            for current vegetation heights (derived from LiDAR) and stream width as
                            determined by hand digitized riverbanks using July 2019 NAIP imagery.
                            TFT used geospatial techniques to determine the southern bank along each
                            modeled river, and using LiDAR analyzed areas where vegetation
                            conditions could be improved within a 60ft buffer of the bank. A
                            vegetation threshold of 10m was used, where any area with vegetation
                            heights less than 10m in the baseline data was changed to 30m to
                            represent potential future conditions. The difference in thermal load
                            between the baseline and future conditions is expressed as kilocalories
                            per day, summed for each tax lot within the 60ft riparian buffers.{' '}
                        </Typography>
                    </li>
                    <li id='ntt-field-sediment'>
                        <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                            c. NTT: Field Sediment
                        </Typography>
                        <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                            TFT analyzed potential sediment and nutrient abatement scenarios on a
                            field-by-field basis to determine the scenario that offers the greatest
                            reduction in sediment and nutrient run-off. This analysis included best
                            management practice (BMPs) that achieve reductions and are compatible
                            with the agronomic practices common in the project area. TFT modeled
                            edge-of-field runoff for every scenario, then compared current and
                            future scenarios for each field to determine which scenario offered the
                            potential for the greatest reduction in sediment runoff. The Nutrient
                            Tracking Tool (NTT; Saleh et al. 2011) serves as the underlying modeling
                            engine for this process. NTT is a site-specific model that estimates
                            nutrient and sediment losses for crop and pasture at the field scale.
                            NTT estimates are made using the APEX (Agricultural Policy/Extender;
                            Williams et al. 2010) (Version 0806) model (Williams et al. 2000).
                            Results represent average nutrient and sediment losses from the field
                            based on 35 years of simulated weather. NTT was developed by the Texas
                            Institute for Applied Environmental Research (TIAER) at Tarleton State
                            University with funding and technical support from USDA’s Office of
                            Environmental Markets.
                        </Typography>
                    </li>
                    <li id='graip-lite-road-sediment'>
                        <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                            d. GRAIP Lite: Road Sediment
                        </Typography>
                        <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                            TFT analyzed sediment runoff reduction opportunities for public and
                            private road sections using a composite of the Oregon Transportation
                            Network (2017) and National Forest System Roads GIS data, using six road
                            treatment types in the Rogue Basin within the GRAIP Lite model. GRAIP
                            (Geomorphic Road Analysis and Inventory) Lite is a more generalized
                            version of the full GRAIP model and is best suited for prioritization
                            across a broad area. With guidance from USFS, TFT modeled and applied
                            the following costs:
                        </Typography>

                        <table style={{ margin: '16px auto' }}>
                            <tr>
                                <th>Treatment Type</th>
                                <th>Cost per mile</th>
                            </tr>
                            <tr>
                                <td>
                                    <Typography
                                        style={{
                                            lineHeight: '1.8em',
                                            fontWeight: 300,
                                            margin: '4px 32px 4px 0px'
                                        }}
                                    >
                                        {' '}
                                        1. Traffic Increase – ML2 with Gravel
                                    </Typography>{' '}
                                </td>
                                <td>
                                    <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                        {' '}
                                        $50,000
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography
                                        style={{
                                            lineHeight: '1.8em',
                                            fontWeight: 300,
                                            margin: '4px 32px 4px 0px'
                                        }}
                                    >
                                        {' '}
                                        1. Traffic Increase – ML3 with Gravel
                                    </Typography>
                                </td>
                                <td>
                                    <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                        {' '}
                                        $50,000
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography
                                        style={{
                                            lineHeight: '1.8em',
                                            fontWeight: 300,
                                            margin: '4px 32px 4px 0px'
                                        }}
                                    >
                                        {' '}
                                        1. Reconstruct – ML2
                                    </Typography>
                                </td>
                                <td>
                                    <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                        {' '}
                                        $7,000
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography
                                        style={{
                                            lineHeight: '1.8em',
                                            fontWeight: 300,
                                            margin: '4px 32px 4px 0px'
                                        }}
                                    >
                                        {' '}
                                        1. Reconstruct – ML3
                                    </Typography>
                                </td>
                                <td>
                                    <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                        {' '}
                                        $7,000
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography
                                        style={{
                                            lineHeight: '1.8em',
                                            fontWeight: 300,
                                            margin: '4px 32px 4px 0px'
                                        }}
                                    >
                                        {' '}
                                        1. Storage – Close ML2
                                    </Typography>
                                </td>
                                <td>
                                    {' '}
                                    <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                        {' '}
                                        $10,000
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography
                                        style={{
                                            lineHeight: '1.8em',
                                            fontWeight: 300,
                                            margin: '4px 32px 4px 0px'
                                        }}
                                    >
                                        {' '}
                                        1. Decommission – Rip/Till
                                    </Typography>
                                </td>
                                <td>
                                    <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                        {' '}
                                        $10,000
                                    </Typography>
                                </td>
                            </tr>
                        </table>

                        <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                            For each treatment type on each road segment, the output sediment values
                            (kg/yr) were compared to the current baseline data, both for total
                            sediment production and total sediment routed to a stream.{' '}
                        </Typography>
                    </li>

                    <li id='culvert-risk-analysis'>
                        <Typography style={{ fontWeight: 500, margin: '8px 0' }}>
                            e. Culvert Risk Analysis
                        </Typography>
                        <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                            TFT created a culvert risk assessment map based on several criteria
                            using culvert locations provided by{' '}
                            <a href='https://nrimp.dfw.state.or.us/nrimp/default.aspx?pn=fishbarrierdata'>
                                ODFW
                            </a>
                            . Using a series of tools from{' '}
                            <a href='http://www.netmaptools.org/Pages/NetMapHelp/introduction.htm?mw=MjQw&st=MQ==&sct=MzU4&ms=AAAAAAA='>
                                NetMap
                            </a>
                            , the following attributes were created for each culvert location:
                        </Typography>

                        <ol style={{ margin: '8px 0' }}>
                            <li>
                                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                    Upstream avg drainage area – Mean drainage area (sq km) of all
                                    stream reaches above culvert location
                                </Typography>
                            </li>
                            <li>
                                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                    Upstream avg % slope – The percent slope of the stream above
                                    culvert location
                                </Typography>
                            </li>
                            <li>
                                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                    Upstream avg Stream Power Index – Mean Stream Power Index (
                                    <a href='http://www.netmaptools.org/Pages/NetMapHelp/stream_power_2.htm'>
                                        SPI
                                    </a>
                                    ) of stream above culvert location
                                </Typography>
                            </li>

                            <li>
                                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                    Upstream avg flow velocity – Mean{' '}
                                    <a href='http://www.netmaptools.org/Pages/NetMapHelp/flow_velocity.htm'>
                                        flow velocity
                                    </a>{' '}
                                    (m/s) of stream above culvert location
                                </Typography>
                            </li>

                            <li>
                                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                    Downstream Cohi IP – Coho Intrinsic Potential (
                                    <a href='http://www.netmaptools.org/Pages/NetMapHelp/intrinsic_potential__anadromous_.htm'>
                                        IP
                                    </a>
                                    ) downstream of culvert location
                                </Typography>
                            </li>

                            <li>
                                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                    Downstream Steelhead IP – Steelhead Intrinsic Potential
                                    downstream of culvert location
                                </Typography>
                            </li>

                            <li>
                                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                    Downstream Chinook IP– Chinook Intrinsic Potential downstream of
                                    culvert location
                                </Typography>
                            </li>

                            <li>
                                <Typography style={{ lineHeight: '1.8em', fontWeight: 300 }}>
                                    Anadromy – Yes/No if culvert stream is habitat to anadromous
                                    fish
                                </Typography>
                            </li>
                        </ol>
                        <Typography style={{ lineHeight: '1.8em', fontWeight: 300, paddingBottom: '16px' }}>
                            Results of the culvert risk analysis can be further explore on this <a href='https://freshwatertrust.maps.arcgis.com/apps/webappviewer/index.html?id=25357942e0fd4db3b04ace498a78fe6a'>ESRI webmap </a> produced in association with this project.
                        </Typography>
                    </li>
                </ul>
            </div>
        </Container>
    );
};

export default About;
