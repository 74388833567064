import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto'
    },
    filterContainer: {
        display: 'flex',
        padding: theme.spacing(2),
        width: '300px'
    },
    formGroup: {
        width: '90%',
        color: 'rgba(0, 0, 0, 0.73)'
    },
    formLabel: {
        margin: '0 0 1.25em 14px'
    },
    listWrapper: {
        borderLeft: '1px dashed rgba(0, 0, 0, 0.4)',
        paddingLeft: '18px',
        marginLeft: '9px',
        listStyle: 'none'
    },
    checkbox: {
        padding: '5px 5px 5px 8px',
        color: 'rgb(87, 106, 207) !important'
    },
    button: {
        color: 'rgba(8, 7, 11, 0.87)',
        backgroundColor: '#fff',
        border: '1px solid #cac5c5',
        fontFamily: 'Open sans',
        padding: '5px 16px 5px 16px',
        margin: '5px',
        textTransform: 'unset',
        '&:hover': {
            backgroundColor: '#fbfbfb'
        }
    }
}));

const TableFilter = (props) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const { map, data, setRows } = props;

    const [watershed, setWatershed] = useState({
        'All watersheds': true,
        'Middle Rogue': true,
        'Upper Rogue': true,
        'Applegate River': true,
        'Illinois River': true,
        'Lower Rogue': true
    });

    const [subwater, setSubwater] = useState({
        'Antelope Creek': true,
        'East Fork Illinois River': true,
        'Elk Creek': true,
        'Evans Creek': true,
        'Little Butte & North Fork Little Butte Creek': true,
        'South Fork Little Butte Creek': true,
        'Sucker Creek': true,
        'Other Upper Rogue': true,
        'Other Illinois River': true,
        'Other Applegate River': true,
        'Other Middle Rogue': true,
        'Other Lower Rogue': true
    });

    const parent = {
        'Antelope Creek': 'Upper Rogue',
        'Elk Creek': 'Upper Rogue',
        'Little Butte & North Fork Little Butte Creek': 'Upper Rogue',
        'South Fork Little Butte Creek': 'Upper Rogue',
        'East Fork Illinois River': 'Illinois River',
        'Sucker Creek': 'Illinois River',
        'Evans Creek': 'Middle Rogue',
        'Other Upper Rogue': 'Upper Rogue',
        'Other Illinois River': 'Illinois River',
        'Other Applegate River': 'Applegate River',
        'Other Middle Rogue': 'Middle Rogue',
        'Other Lower Rogue': 'Lower Rogue'
    };
    const watersheds = [
        'Upper Rogue',
        'Antelope Creek',
        'Elk Creek',
        'Little Butte & North Fork Butte',
        'South Fork Little Butte',
        'Other Upper Rogue',
        'Illinois River',
        'East Fork Illinois River',
        'Sucker Creek',
        'Other Illinois River',
        'Middle Rogue',
        'Evans Creek',
        'Other Middle Rogue',
        'Lower Rogue',
        'Other Lower Rogue',
        'Applegate River',
        'Other Applegate River'
    ];

    const dependencies = {
        'Upper Rogue': [1, 5],
        'Illinois River': [7, 9],
        'Middle Rogue': [11, 12],
        'Lower Rogue': [14, 14],
        'Applegate River': [16, 16]
    };

    useEffect(() => {
        if (data && data.features) {
            const filterData = [];
            data.features.forEach((value) => {
                // is subwatershed marked or unmarked
                const marked = subwater[value.properties.subwatershed];
                // is parent marked or unmarked
                const activeParent = watershed[parent[value.properties.subwatershed]];
                // is "All watersheds marked" or unmarked
                const renderAll = watershed['All watersheds'];

                if (marked && activeParent && renderAll) {
                    filterData.push(value.properties);
                }
            });

            setRows(filterData);
        }
    }, [watershed, subwater]);

    const checkDependecies = (key) => {
        if (key === 'All watersheds') {
            // check all watersheds and their dependencies
            for (let w in dependencies) {
                for (let i = dependencies[w][0]; i <= dependencies[w][1]; i++) {
                    if (subwater[watersheds[i]] && watershed[w]) {
                        return true;
                    }
                }
            }
            return false;
        }
        // check watershed and its dependencies
        for (let i = dependencies[key][0]; i <= dependencies[key][1]; i++) {
            if (watershed[watersheds[i]] || subwater[watersheds[i]]) {
                return true;
            }
        }
        return false;
    };

    const handleChange = (event) => {
        // toogle checkbox
        setWatershed({ ...watershed, [event.target.name]: event.target.checked });
        setSubwater({ ...subwater, [event.target.name]: event.target.checked });
    };

    return (
        <div className={classes.root}>
            <Tooltip title='Filter'>
                <IconButton
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    aria-label='Filter'
                >
                    <FilterListIcon />
                </IconButton>
            </Tooltip>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <div className={classes.filterContainer}>
                    <FormGroup className={classes.formGroup}>
                        <ul style={{ paddingLeft: '6px', listStyle: 'none', marginTop: '0' }}>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                watershed['All watersheds'] &&
                                                checkDependecies('All watersheds')
                                            }
                                            onChange={handleChange}
                                            name='All watersheds'
                                            className={classes.checkbox}
                                        />
                                    }
                                    label={
                                        <Typography className={classes.text}>
                                            All watersheds
                                        </Typography>
                                    }
                                />

                                <ul className={classes.listWrapper}>
                                    <li>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        watershed['Upper Rogue'] &&
                                                        watershed['All watersheds'] &&
                                                        checkDependecies('Upper Rogue')
                                                    }
                                                    onChange={handleChange}
                                                    name='Upper Rogue'
                                                    className={classes.checkbox}
                                                />
                                            }
                                            label={
                                                <Typography className={classes.text}>
                                                    Upper Rogue
                                                </Typography>
                                            }
                                        />
                                    </li>
                                    <ul className={classes.listWrapper}>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Antelope Creek'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Upper Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Antelope Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Antelope Creek
                                                    </Typography>
                                                }
                                            />
                                        </li>

                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Elk Creek'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Upper Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Elk Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Elk Creek
                                                    </Typography>
                                                }
                                            />
                                        </li>

                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater[
                                                                'Little Butte & North Fork Little Butte Creek'
                                                            ] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Upper Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Little Butte & North Fork Little Butte Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Little Butte & North Fork Butte
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater[
                                                                'South Fork Little Butte Creek'
                                                            ] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Upper Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='South Fork Little Butte Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        South Fork Little Butte
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Other Upper Rogue'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Upper Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Other Upper Rogue'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Other
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                    </ul>

                                    <li>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        watershed['Illinois River'] &&
                                                        watershed['All watersheds'] &&
                                                        checkDependecies('Illinois River')
                                                    }
                                                    onChange={handleChange}
                                                    name='Illinois River'
                                                    className={classes.checkbox}
                                                />
                                            }
                                            label={
                                                <Typography className={classes.text}>
                                                    Illinois River
                                                </Typography>
                                            }
                                        />
                                    </li>

                                    <ul className={classes.listWrapper}>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['East Fork Illinois River'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Illinois River']
                                                        }
                                                        onChange={handleChange}
                                                        name='East Fork Illinois River'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        East Fork Illinois River
                                                    </Typography>
                                                }
                                            />
                                        </li>

                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Sucker Creek'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Illinois River']
                                                        }
                                                        onChange={handleChange}
                                                        name='Sucker Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Sucker Creek
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Other Illinois River'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Illinois River']
                                                        }
                                                        onChange={handleChange}
                                                        name='Other Illinois River'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Other
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                    </ul>
                                    <li>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        watershed['Middle Rogue'] &&
                                                        watershed['All watersheds'] &&
                                                        checkDependecies('Middle Rogue')
                                                    }
                                                    onChange={handleChange}
                                                    name='Middle Rogue'
                                                    className={classes.checkbox}
                                                />
                                            }
                                            label={
                                                <Typography className={classes.text}>
                                                    Middle Rogue
                                                </Typography>
                                            }
                                        />
                                    </li>
                                    <ul className={classes.listWrapper}>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Evans Creek'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Middle Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Evans Creek'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Evans Creek
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Other Middle Rogue'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Middle Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Other Middle Rogue'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Other
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                    </ul>
                                    <li>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        watershed['Lower Rogue'] &&
                                                        watershed['All watersheds'] &&
                                                        checkDependecies('Lower Rogue')
                                                    }
                                                    onChange={handleChange}
                                                    name='Lower Rogue'
                                                    className={classes.checkbox}
                                                />
                                            }
                                            label={
                                                <Typography className={classes.text}>
                                                    Lower Rogue
                                                </Typography>
                                            }
                                        />
                                    </li>
                                    <ul className={classes.listWrapper}>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Other Lower Rogue'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Lower Rogue']
                                                        }
                                                        onChange={handleChange}
                                                        name='Other Lower Rogue'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Other
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                    </ul>

                                    <li>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        watershed['Applegate River'] &&
                                                        watershed['All watersheds'] &&
                                                        checkDependecies('Applegate River')
                                                    }
                                                    onChange={handleChange}
                                                    name='Applegate River'
                                                    className={classes.checkbox}
                                                />
                                            }
                                            label={
                                                <Typography className={classes.text}>
                                                    Applegate
                                                </Typography>
                                            }
                                        />
                                    </li>
                                    <ul className={classes.listWrapper}>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            subwater['Other Applegate River'] &&
                                                            watershed['All watersheds'] &&
                                                            watershed['Applegate River']
                                                        }
                                                        onChange={handleChange}
                                                        name='Other Applegate River'
                                                        className={classes.checkbox}
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.text}>
                                                        Other
                                                    </Typography>
                                                }
                                            />
                                        </li>
                                    </ul>
                                </ul>
                            </li>
                        </ul>
                    </FormGroup>
                </div>
            </Popover>
        </div>
    );
};

export default TableFilter;
