import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTokenStore } from '../../auth/useTokenStore';

export const Logout = ({ children }) => {
    const history = useHistory();
    const [hasTokens, setTokens] = useTokenStore((s) => [
        !!(s.accessToken && s.refreshToken),
        s.setTokens
    ]);

    useEffect(() => {
        if (!hasTokens) {
            history.push('/');
        }
    }, [hasTokens]);

    return children;
};

export default Logout;
