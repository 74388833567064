import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import AlertProvider from '../contexts/AlertProvider';
import outlookIcon from '../../img/outlook_icon.png';


const useStyles = makeStyles({
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0px',
        padding: '16px',
        fontSize: '16px',
        textalign: 'center'
    },
    titleText: {
        fontWeight: '500',
        color: 'rgb(66, 63, 74)'
    },
    text: {
        color: 'rgba(0, 0, 0, 0.75)',
        marginBottom: '2rem',
        textAlign: 'center'
    },

    closeButton: {
        position: 'absolute',
        left: 0
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    button: {
        width: '100%',
        margin: 'auto',
        color: 'rgb(66, 63, 74)',
        marginBottom: '16px',
        border: '2px solid rgb(176, 176, 176) ',
        borderRadius: '8px',
        fontSize: '0.8em',
        textTransform: 'unset',
        padding: '0',
        borderColor: 'rgb(168, 168, 168)',
        padding: '7px 16px 9px',
        '&:hover': {
            borderColor: 'rgba(21, 26, 49, 0.68)',
            background: '#fff'
        }
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
    const classes = useStyles();
    const alert = useContext(AlertProvider.context);
    const serverAddress = process.env.REACT_APP_PUBLIC_SERVER_ADDRESS;

    const handleClose = () => {
        alert.setAlert({ status: '' });
    };

    return (
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description'
            fullWidth={true}
            maxWidth='xs'
        >
            <DialogTitle disableTypography className={classes.title}>
                <Typography className={classes.titleText} variant='subtitle1'>
                    Welcome to BasinScout Analytics
                </Typography>
                <IconButton
                    onClick={handleClose}
                    aria-label='close'
                    className={classes.closeButton}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <Typography className={classes.text} variant='body1'>
                    <br />
                    Please log in to access map, table, and program development features.
                </Typography>

                <div className={classes.buttonWrapper}>
                    <a href={serverAddress + '/auth/outlook'}>
                        <Button className={classes.button} variant='outlined'>
                            <img src={outlookIcon} height={20} style={{
                                marginLeft: '1rem',
                                position: 'absolute',
                                left: 0
                            }} />
                            <Typography style={{ fontSize: '0.95rem' }}>
                                Continue with Outlook
                            </Typography>
                        </Button>
                    </a>

                    <a href={serverAddress + '/auth/google'}>
                        <Button className={classes.button} variant='outlined'>
                            <i
                                className='fa fa-google'
                                style={{
                                    fontSize: '1.5em',
                                    color: 'rgb(203, 64, 36)',
                                    marginLeft: '1rem',
                                    position: 'absolute',
                                    left: 0
                                }}
                            ></i>
                            <Typography style={{ fontSize: '0.95rem' }}>
                                Continue with Google
                            </Typography>
                        </Button>
                    </a>
                    {/* <a href={serverAddress + '/auth/linkedin'}>
                        <Button className={classes.button} variant='outlined'>
                            <i
                                className='fa fa-linkedin'
                                style={{
                                    fontSize: '1.5em',
                                    color: 'rgb(10, 102, 194)',
                                    marginLeft: '1rem',
                                    position: 'absolute',
                                    left: 0
                                }}
                            ></i>

                            <Typography style={{ fontSize: '0.95rem' }}>
                                Continue with LinkedIn
                            </Typography>
                        </Button>
                    </a>
                    <a href={serverAddress + '/auth/github'}>
                        <Button className={classes.button} variant='outlined'>
                            <i
                                className='fa fa-github'
                                style={{
                                    fontSize: '1.5em',
                                    marginLeft: '1rem',
                                    position: 'absolute',
                                    left: 0
                                }}
                            ></i>

                            <Typography style={{ fontSize: '0.95rem' }}>
                                Continue with Github
                            </Typography>
                        </Button>
                    </a> */}
                </div>
            </DialogContent>
        </Dialog>
    );
}
