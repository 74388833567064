/*
 * API Calls
 */
import { useSecureRequest } from '../auth/useSecureRequest';

export const useApi = () => {
    const { secureRequest } = useSecureRequest();

    return {
        /* Geographical data API calls */
        fetchHsData: (callBack) => fetchHsData(secureRequest, callBack),
        fetchFlowData: (callBack) => fetchFlowData(secureRequest, callBack),
        fetchHsFlowData: (callBack) => fetchHsFlowData(secureRequest, callBack),
        fetchShadeData: (callBack) => fetchShadeData(secureRequest, callBack),
        fetchNttData: (callBack) => fetchNttData(secureRequest, callBack),
        fetchGraipData: (callBack) => fetchGraipData(secureRequest, callBack),
        fetchWatershedData: (callBack) => fetchWatershedData(secureRequest, callBack),
        fetchSubwaterData: (callBack) => fetchSubwaterData(secureRequest, callBack),
        fetchCulvertData: (callBack) => fetchCulvertData(secureRequest, callBack),
        fetchTaxlotData: (callBack) => fetchTaxlotData(secureRequest, callBack),

        /* Programs API calls */
        createProgram: (name, projects, key) => createProgram(secureRequest, name, projects, key),
        deleteProgram: (programId) => deleteProgram(secureRequest, programId),
        fetchPrograms: () => fetchPrograms(secureRequest),
        fetchUserPrograms: () => fetchUserPrograms(secureRequest),

        fetchProjects: (programId) => fetchProjects(secureRequest, programId),
        addProject: (programName, projects, key) =>
            addProject(secureRequest, programName, projects, key),
        deleteProject: (key, projectId) => deleteProject(secureRequest, key, projectId)
    };
};

/* Geographical data API calls */
export const fetchFlowData = async (secureRequest, callBack) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/flow' });
        if (response.status === 200 && callBack) callBack(response.data);
        return response;
    } catch (error) {
        console.log(error);
    }
};

const fetchHsData = async (secureRequest, callBack) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/hs' });
        if (response.status === 200 && callBack) callBack(response.data);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const fetchHsFlowData = async (secureRequest, callBack) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/hsflow' });
        if (response.status === 200 && callBack) callBack(response.data);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const fetchShadeData = async (secureRequest, callBack) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/shade' });
        if (response.status === 200 && callBack) callBack(response.data);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const fetchNttData = async (secureRequest, callBack) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/ntt' });
        if (response.status === 200 && callBack) callBack(response.data);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const fetchGraipData = async (secureRequest, callBack) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/graip' });
        if (response.status === 200 && callBack) callBack(response.data);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const fetchWatershedData = async (secureRequest, callBack) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/watersheds' });
        if (response.status === 200 && callBack) callBack(response.data);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const fetchSubwaterData = async (secureRequest, callBack) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/subwatersheds' });
        if (response.status === 200 && callBack) callBack(response.data);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const fetchCulvertData = async (secureRequest, callBack) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/culverts' });
        if (response.status === 200 && callBack) callBack(response.data);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const fetchTaxlotData = async (secureRequest, callBack) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/taxlots' });
        if (response.status === 200 && callBack) callBack(response.data);
        return response;
    } catch (error) {
        console.log(error);
    }
};

/* Programs API calls */
export const createProgram = async (secureRequest, name, projects, key) => {
    try {
        const response = await secureRequest({
            type: 'POST',
            endpoint: '/program/create',
            data: { name, projects, key }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const deleteProgram = async (secureRequest, programId) => {
    try {
        const response = await secureRequest({
            type: 'POST',
            endpoint: '/program/delete',
            data: { programId }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const fetchPrograms = async (secureRequest) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/program/fetch' });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const fetchUserPrograms = async (secureRequest) => {
    try {
        const response = await secureRequest({ type: 'GET', endpoint: '/program/user_fetch' });
        return response;
    } catch (error) {
        console.log(error);
    }
};

/* Projects API calls */
export const fetchProjects = async (secureRequest, programId) => {
    try {
        const response = await secureRequest({
            type: 'GET',
            endpoint: '/program/projects',
            data: { programId }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const addProject = async (secureRequest, programName, projects, key) => {
    try {
        const response = await secureRequest({
            type: 'POST',
            endpoint: '/program/add_project',
            data: { programName, projects, key }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const deleteProject = async (secureRequest, key, projectId) => {
    try {
        const response = await secureRequest({
            type: 'POST',
            endpoint: '/program/delete_project',
            data: { key, projectId }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};
