import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NavBar from './NavBar/NavBar';
import Map from './Map/Map';
import Home from './Home/Home';
import NttTable from './Table/NttTable';
import ShadeTable from './Table/ShadeTable';
import GraipTable from './Table/GraipTable';
import FlowTable from './Table/FlowTable';
import HsFlowTable from './Table/HsFlowTable';
import HeatSourceTable from './Table/HeatSourceTable';
import Programs from './Programs/Programs';
import AlertProvider from './contexts/AlertProvider';
import About from './About/About'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import WaitForAuth from '../auth/WaitForAuth';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        display: 'flex',
        height: '100%',
        width: '100%',
        flexFlow: 'column'
    }
}));

const Root = () => {
    const classes = useStyles();
    const [resizeMap, setResizeMap] = useState(false);
    const [map, setMap] = useState(null);

    // geographical data
    const [hsData, setHs] = useState(null);
    const [hsFlowData, setHsFlow] = useState(null);
    const [flowData, setFlow] = useState(null);
    const [shadeData, setShade] = useState(null);
    const [nttData, setNtt] = useState(null);
    const [graipData, setGraip] = useState(null);
    const [watershedData, setWatershed] = useState(null);
    const [subwaterData, setSubwater] = useState(null);
    const [culvertData, setCulvert] = useState(null);
    const [taxlotData, setTaxlot] = useState(null);

    // data initialization Flags
    const [hsInMap, setHsInMap] = useState(false);
    const [flowInMap, setFlowInMap] = useState(false);
    const [hsFlowInMap, setHsFlowInMap] = useState(false);
    const [shadeInMap, setShadeInMap] = useState(false);
    const [nttInMap, setNttInMap] = useState(false);
    const [graipInMap, setGraipInMap] = useState(false);
    const [watershedInMap, setWatershedInMap] = useState(false);
    const [subwaterInMap, setSubwaterInMap] = useState(false);
    const [culvertInMap, setCulvertInMap] = useState(false);
    const [taxlotInMap, setTaxlotInMap] = useState(false);

    // navbar Flag
    const [navBarSync, setNavBarSync] = useState(false);

    return (
        <Router>
            <div className={classes.rootContainer}>
                <AlertProvider>
                    <NavBar
                        setResizeMap={setResizeMap}
                        navBarSync={navBarSync}
                        setNavBarSync={setNavBarSync}
                    />
                    <Switch>
                        <Route
                            exact
                            path='/'
                            render={(props) => <Home {...props} setNavBarSync={setNavBarSync} />}
                        />

                        <Route
                            exact
                            path='/flow-table'
                            render={(props) => (
                                <WaitForAuth>
                                    <FlowTable
                                        {...props}
                                        map={map}
                                        data={flowData}
                                        setNavBarSync={setNavBarSync}
                                    />
                                </WaitForAuth>
                            )}
                        />

                        <Route
                            exact
                            path='/ntt-table'
                            render={(props) => (
                                <WaitForAuth>
                                    <NttTable
                                        {...props}
                                        map={map}
                                        data={nttData}
                                        setNavBarSync={setNavBarSync}
                                    />
                                </WaitForAuth>
                            )}
                        />

                        <Route
                            exact
                            path='/shade-table'
                            render={(props) => (
                                <WaitForAuth>
                                    <ShadeTable
                                        {...props}
                                        map={map}
                                        data={shadeData}
                                        setNavBarSync={setNavBarSync}
                                    />
                                </WaitForAuth>
                            )}
                        />

                        <Route
                            exact
                            path='/graip-table'
                            render={(props) => (
                                <WaitForAuth>
                                    <GraipTable
                                        {...props}
                                        map={map}
                                        data={graipData}
                                        setNavBarSync={setNavBarSync}
                                    />
                                </WaitForAuth>
                            )}
                        />

                        <Route
                            exact
                            path='/heatsource-table'
                            render={(props) => (
                                <WaitForAuth>
                                    <HeatSourceTable
                                        {...props}
                                        map={map}
                                        data={hsData}
                                        setNavBarSync={setNavBarSync}
                                    />
                                </WaitForAuth>
                            )}
                        />

                        <Route
                            exact
                            path='/heatsource-flow-table'
                            render={(props) => (
                                <WaitForAuth>
                                    <HsFlowTable
                                        {...props}
                                        map={map}
                                        data={hsFlowData}
                                        setNavBarSync={setNavBarSync}
                                    />
                                </WaitForAuth>
                            )}
                        />

                        <Route
                            path='/programs'
                            render={(props) => (
                                <WaitForAuth>
                                    <Programs {...props} map={map} setNavBarSync={setNavBarSync} />
                                </WaitForAuth>
                            )}
                        />

<Route
                            path='/about-this-tool'
                            render={(props) => (
                                <WaitForAuth>
                                  <About />
                                </WaitForAuth>
                            )}
                        />
                    </Switch>
                    <WaitForAuth>
                        <Map
                            map={map}
                            setMap={setMap}
                            resizeMap={resizeMap}
                            setResizeMap={setResizeMap}
                            loading={false}
                            hsData={hsData}
                            hsFlowData={hsFlowData}
                            flowData={flowData}
                            shadeData={shadeData}
                            nttData={nttData}
                            graipData={graipData}
                            watershedData={watershedData}
                            subwaterData={subwaterData}
                            culvertData={culvertData}
                            taxlotData={taxlotData}
                            setHs={setHs}
                            setHsFlow={setHsFlow}
                            setFlow={setFlow}
                            setShade={setShade}
                            setNtt={setNtt}
                            setGraip={setGraip}
                            setWatershed={setWatershed}
                            setSubwater={setSubwater}
                            setCulvert={setCulvert}
                            setTaxlot={setTaxlot}
                            hsInMap={hsInMap}
                            hsFlowInMap={hsFlowInMap}
                            flowInMap={flowInMap}
                            shadeInMap={shadeInMap}
                            nttInMap={nttInMap}
                            graipInMap={graipInMap}
                            watershedInMap={watershedInMap}
                            subwaterInMap={subwaterInMap}
                            culvertInMap={culvertInMap}
                            taxlotInMap={taxlotInMap}
                            setShadeInMap={setShadeInMap}
                            setNttInMap={setNttInMap}
                            setGraipInMap={setGraipInMap}
                            setHsInMap={setHsInMap}
                            setHsFlowInMap={setHsFlowInMap}
                            setFlowInMap={setFlowInMap}
                            setFlowInMap={setFlowInMap}
                            setWatershedInMap={setWatershedInMap}
                            setSubwaterInMap={setSubwaterInMap}
                            setCulvertInMap={setCulvertInMap}
                            setTaxlotInMap={setTaxlotInMap}
                        />
                    </WaitForAuth>
                </AlertProvider>
            </div>
        </Router>
    );
};

export default Root;
