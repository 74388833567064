import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
    root: {
        padding: '0 20px 0 20px'
    }
});

const marks = [
    {
        value: 0,
        label: 'low'
    },
    {
        value: 100,
        label: 'high'
    }
];

function valueLabelFormat(value) {
    return `${value}%`;
}

export const RangeSlider = (props) => {
    const classes = useStyles();
    const { color, value, setValue } = props;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Slider
                value={value}
                onChange={handleChange}
                valueLabelDisplay='auto'
                aria-labelledby='range-slider'
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                marks={marks}
                step={10}
                style={{ color: color }}
            />
        </div>
    );
};
